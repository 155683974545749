import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import DetailNews from './DetailNews';

const NewsandEvents = () => {
  const dispatch = useDispatch();
  const {top_news} = useSelector((state) => state.Newsfilter);
  return (
    <div className="News_Events">
      <div className="wrapper">
        <h2 className="tile-h">News & Events</h2>
        <div className="flex_news">
          {top_news?.map((news, index) => (
            <DetailNews key={index} news={news} />
          ))}
        </div>
        <button className="va">
          <Link to={'news-events'}>View All</Link>
        </button>
      </div>
    </div>
  );
};

export default NewsandEvents;
