import React from 'react';
import { Link } from 'react-router-dom';

const RedirectSection = ({ message }) => {
  return (
    <div className="round_msg">
      <div>
        <h4>{message.title1}</h4>
        <p>{message.Para}</p>
        <Link to={message.Link}>{message.button}</Link>
      </div>
    </div>
  );
};

export default RedirectSection;
