import React from 'react';
import appstore from './asstes/images/free-app-store-2844886-2365235.webp';
import Playstore from './asstes/images/play-store-color-icon.webp';
import { useSelector, useDispatch } from 'react-redux';

function TopHeader() {
    const { info } = useSelector((state) => state.ContactInfo);
    return (
        <div className="top-s">
            <div className="header-top">
                <div className="wrapper">
                    <div className="top-box-header">
                        <i className="fa-solid fa-phone"></i>
                        <a href={'tel:+91'+info?.mobile}>+91{info?.mobile}</a>
                    </div>
                    <div className="top-box-header" style={{ borderRight: "0" }}>
                        <i className="fa-solid fa-envelope"></i>
                        <a href={'mailto:'+info?.email} style={{ lineHeight: "1.8" }}>{info?.email}</a>
                    </div>
                    <div className="top-box-header" style={{ paddingRight: "0" }}>
                        <img src={Playstore} className="playstore-header" />
                    </div>
                    <div className="top-box-header">
                        <img src={appstore} className="playstore-header" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopHeader
