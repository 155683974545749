import React, { useEffect, useState } from 'react'
import AccountSideBar from './AccountSideBar'
import './asset/Favorites.css'
import { getFavoritesList, removeFavorite } from '../../services/ApiServices'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { fetchAvailabilityData } from '../../store/AvailabilitySearch'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { fetchAvailableSlotByid } from '../../store/AvailableSlot'
import { setselectedSlot } from '../../store/FilterSliceList'
import $ from 'jquery'
import Loaders from '../Loader/Loaders'
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import { toast } from 'react-toastify'

const FavList = () => {
    useEffect(() => {
        getfav({ page })
    }, [])
    const [favlist, setfavlist] = useState([])
    const [loading, setloading] = useState(true)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [calender, setcalender] = useState(false)
    const [memberid, setmemberid] = useState(null)
    const [dates, setDate] = useState(new Date());
    const [selectSlot, setselectSlot] = useState();
    const [isdate, setisdate] = useState(false)
    const [defaultindex, setdefaultindex] = useState(0)
    const [customdate, setcustomdate] = useState(new Date());
    const [selectedDate, setselectedDate] = useState();
    const [selectedDate1, setselectedDate1] = useState();
    const [pagination, setpagination] = useState([])
    const [page, setpage] = useState(1)
    const { availableSlots, sloteMsg } = useSelector((state) => state.availableSlots);
    const currentDate = new Date();
    const options0 = { day: 'numeric', month: 'short' };
    const options = { day: 'numeric', month: 'short', year: "numeric" };
    const formattedDate = currentDate?.toLocaleDateString('en-US', options);
    const nextDay = new Date(currentDate);
    nextDay.setDate(currentDate.getDate() + 1);

    const formattedDate2 = nextDay?.toLocaleDateString('en-US', options);

    const getfav = async ({ page }) => {
        try {
            const response = await getFavoritesList({ page });
            setfavlist(response.Data? response.Data.data : null )
            setpagination(response.Data)
            setloading(false)
        } catch (error) {
            setloading(false)
        }
    }
    const totalPages = Math.ceil(pagination?.total / pagination?.per_page);

    const handlePageChange = (newPage) => {
        setpage(newPage)
        getfav({ page: newPage })
    };
    const handleviewpage = (id, typ) => {
        if (typ == 1) {
            navigate('/view-profile/' + id)
        }
        else {
            navigate('/view-firm-profile/' + id)
        }
    }
    const getAvailableSlot = async (data) => {

        const currentDate = new Date(data?.date);
        // Extract day, month, and year from the currentDate object
        let day = currentDate.getDate();
        let month = currentDate.getMonth() + 1; // Note: January is 0
        let year = currentDate.getFullYear();

        // Format day, month, and year to have leading zeros if necessary
        day = String(day).padStart(2, '0');
        month = String(month).padStart(2, '0');
        const formattedDate1 = currentDate?.toLocaleDateString('en-US', options);

        setselectedDate1(formattedDate1)
        // Create the formatted date string in "dd-mm-yyyy" format
        let formattedDate = `${day}-${month}-${year}`;
        setselectedDate(formattedDate)
        dispatch(fetchAvailabilityData({
            id: data?.id,
            date: formattedDate
        }));
      
    }
    const handleSelectSlot = (id, avail) => {
        if (avail == 1) {

            setselectSlot(id)
            var isModalOpen = $(`exampleModal${memberid}`).hasClass('show');
            var button = document.getElementById("closeNav")
            if (button && isModalOpen) {
                button.click()
            }
            dispatch(setselectedSlot({ id: id, date: selectedDate1 }))
            navigate('/booking-detail/' + memberid)
            dispatch(fetchAvailableSlotByid({ id: id, date: selectedDate }))
        }
    }
    const handlesetDate = (date) => {
        const dateObject = new Date(date);
        setisdate(true)
        const options = { month: 'short', day: 'numeric' };
        const formattedcustomDate = dateObject.toLocaleDateString('en-US', options);

        setDate(date)
        setcalender(!calender)
        setcustomdate(formattedcustomDate)
        getAvailableSlot({
            id: memberid,
            date: date
        })
    }

    const handleCustomSlot = () => {
        setdefaultindex(2)
        if (isdate) {
            setselectSlot()
            getAvailableSlot({
                id: memberid,
                date: dates
            })
        }
    }
    const handleCalender = () => {
        setcalender(!calender)
    }
    const handlemember = (val) => {
        setcalender(false)
        setdefaultindex(0)
        setmemberid(val)
        if (val) {
            getAvailableSlot({
                id: val,
                date: formattedDate
            })
        }
    }
    const handleremFav = async (id) => {
        try {
            const response = await removeFavorite({ id});
            if (response.Status == 1) {
                toast.success(response.Message)
                getfav({ page })
            }
        } catch (error) {

        }

    }
    return (
        <div>
            <Loaders loading={loading} />
            <form>
                <div className="row">
                    <AccountSideBar page="favorite" />
                    <div className="col-12 col-lg-9">
                        <div className="my-accountt">
                            <h2> Favourites </h2>                          
                               <div className="row">
                                {favlist?.length > 0 ?
                                    <>{favlist?.map((obj) => {
                                        return (
                                            <div className="col-12 col-lg-6">                                                                                       
                                            <div className="favorates-main">
                                                <div className="row"  style={{marginBottom:"10px"}}>
                                                    <div className="col-12 col-lg-5">
                                                        <img src={obj?.service_provider_photo_url}></img>
                                                    </div>
                                                    <div className="col-12 col-lg-7">
                                                        <div class="basic_info" style={{width:"unset",borderRight:"0"}}>
                                                            <div>
                                                                <h2>{obj?.service_provider_name}</h2>
                                                                <span class="bs-in"> {obj?.category?.category_name}</span>
                                                                <span class="bs-in"> {obj?.specialty?.speciality_name}  </span>
                                                            </div>
                                                            <div class="booking_info_b">
                                                            
                                                            <div class="top_auto">
                                                                <span class="fees_b">
                                                                {obj?.consultation_fee ?
                                                                        <> <b>Booking Fee : </b> {obj?.consultation_fee}/ Person
                                                                        </>
                                                                        :
                                                                        <div style={{height:"27px"}}></div>
                                                                    }
                                                                    </span>
                                                            </div>
                                                        </div>
                                                            <div class="top_auto">
                                                              
                                                                <div class="loc_info">
                                                                <div class="loc" style={{ marginBottom: "15px", alignItems: "center",display:"flex" }}>
                                                                    <i class="fa-solid fa-location-dot"></i>
                                                                    <p>Calicut</p>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="row" style={{alignItems:"center",justifyContent:"space-between"}}>
                                                    <div className="col-5">
                                                        <a href="/services/Mims%20Hospital?Id=335" class="">
                                                            <button class="view_profile" onClick={() => handleviewpage(obj.id, obj.provider_type)}>View Profile</button>
                                                        </a>
                                                    </div>
                                                    <div className="col-5">
                                                    {obj.provider_type == 1 &&
                                                                    <button className="bookNow_b" data-toggle="modal" data-target={`#exampleModal${obj?.id}`} type='button' onClick={() => handlemember(obj?.id)}>Book Now</button>
                                                                }
                                                    </div>
                                                    <div className="col-2">
                                                    <i class="fa-solid fa-heart" style={{color:"#d72045",fontSize:"28px", cursor:"pointer"}}
                                                    onClick={()=>handleremFav(obj?.id)}
                                                    ></i>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                         
                                        )
                                    })}
                                        <div style={{ marginTop: "50px" }}>
                                            <ResponsivePagination
                                                current={page}
                                                total={totalPages}
                                                onPageChange={handlePageChange}
                                            />
                                        </div>

                                    </> :
                                    <div style={{ textAlign: "center" }}>Favorites is empty !</div>
                                }
                             
                                        
                                        </div>
                            </div>
                       
                    </div>
                </div>
            </form>

            <div className="modal fade button-main" id={`exampleModal${memberid}`} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <Tabs selectedIndex={defaultindex}>
                            <TabList>
                                <Tab onClick={() => {
                                    getAvailableSlot({
                                        id: memberid,
                                        date: formattedDate
                                    })
                                    setdefaultindex(0)
                                }
                                }
                                >Today <br />
                                    <span>{currentDate?.toLocaleDateString('en-US', options0)}</span>
                                </Tab>
                                <Tab onClick={() => {
                                    getAvailableSlot({
                                        id: memberid,
                                        date: formattedDate2
                                    })
                                    setdefaultindex(1)
                                }
                                }> Tommorom <br />
                                    <span>{nextDay?.toLocaleDateString('en-US', options0)}</span>
                                </Tab>
                                <Tab onClick={handleCustomSlot}>
                                    More date <br />
                                    <span onClick={handleCalender} style={{ cursor: "pointer" }}>{isdate ? customdate : "See Calendar"}</span>
                                </Tab>
                            </TabList>

                            {calender && (
                                <div >
                                    <div className='calendar-container'>
                                        <Calendar onChange={handlesetDate} value={dates} minDate={currentDate} />
                                    </div>

                                </div>
                            )}
                            {availableSlots ?
                                <div className="row large-2">
                                    <div className="row">
                                        {availableSlots?.map((slot, index) => {
                                            const date = new Date(`2000-01-01T${slot.time_start}`);
                                            const formattedStart = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
                                            const date2 = new Date(`2000-01-01T${slot.time_end}`);
                                            const formattedEnd = date2.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
                                            return (
                                                <div className="col-4" key={slot?.id}>
                                                    <div className={slot.is_expired == 1 ? "availblae-active-checktime" :
                                                        slot.is_available == 0 ? "availblae-active-disactive" :
                                                            slot.id === selectSlot ? "availblae" : "availblae-active"}
                                                        onClick={() => handleSelectSlot(slot.id, slot.is_available)}
                                                        data-dismiss={slot.is_expired == 1?"":"modal"} aria-label="Close" >
                                                        {formattedStart} - {formattedEnd}<br />
                                                        {slot.remaining_count} Available
                                                    </div>
                                                </div>

                                            )
                                        })}
                                    </div>

                                </div> :
                                <div className="row large-2" >
                                    <div className='col-12' style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <p>{sloteMsg}</p>
                                    </div>
                                </div>
                            }
                        </Tabs>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default FavList