import AccountForm from "../components/Account/AccountForm";
import MyfamilyForm from "../components/Account/MyfamilyForm"
import BookingList from "../components/Account/BookingList";
import FavList from "../components/Account/FavList";
import BookHistoryList from "../components/Account/BookHistoryList";
import NotificationList from "../components/Account/NotificationList";
import ChangePassword from "../components/Account/ChangePassword.";
import MyfamilyAdd from "../components/Account/MyfamilyAdd";
import UploadKycForm from "../components/Account/UploadKycForm";
import MyfamilyformEdit from "../components/Account/MyfamilyformEdit";

export const RouterData2 = [
    { path: `my-account`, element: <AccountForm /> },
    { path: `my-family`, element: <MyfamilyForm /> },
    { path: `booking-list`, element: <BookingList /> },
    { path: `booking-history-list`, element: <BookHistoryList /> },
    { path: `favorites`, element: <FavList /> },
    { path: `notification`, element: <NotificationList /> },
    { path: `change-password`, element: <ChangePassword /> },
    { path: `edit-member`, element: <MyfamilyformEdit /> },
    { path: `add-member`, element: <MyfamilyAdd /> },
    { path: `kyc`, element: <UploadKycForm/> },
];