import React from 'react'
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Backdrop from "@mui/material/Backdrop";

const Loaders = ({loading}) => {
  return (
    <div>
        <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >

        <Loader type="ThreeDots" color="white" height={70} width={70} />
      </Backdrop>
        </div>

  )
}

export default Loaders