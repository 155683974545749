import React, { useState } from 'react';
import pswd from '../../assets/images/pswd1.png';
import AuthTitle from './AuthTitle';
import { useNavigate, useLocation } from 'react-router-dom';
import { ResendPaswordOTP, VerifyPaswordOTP } from '../../services/ApiServices';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ResendVerifyOtp() {

    const navigate = useNavigate();
    const location = useLocation();
    const title = 'Enter Your OTP';
    const [otp, setOtp] = useState(location.state.data.otp);
    const [loader, setLoader] = useState(false);
    const [selectedLink, setSelectedLink] = useState('');
    const [error, setError] = useState('');
    const [resendDisabled, setResendDisabled] = useState(false);
    const [countdown, setCountdown] = useState(30);
    const schema = yup.object().shape({
        otp: yup.string().required("otp is required"),
    })
    const {
        register,
        handleSubmit,
        reset,
        clearErrors,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const startCountdown = () => {
        setResendDisabled(true);

        const countdownInterval = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);

        setTimeout(() => {
            clearInterval(countdownInterval);
            setCountdown(30);
            setResendDisabled(false);
        }, 30000);
    };

    const onSubmit = async (data) => {
        // Implement your verification logic here
        try {
            const response = await VerifyPaswordOTP({
                customer_id: location?.state?.data?.customer_id,
                otp: data?.otp
            });
            if(response.Status===1){
                toast.success(response.Message)
                setTimeout(() => {
                    navigate('/forgot-reset',{ state: { customerid: location?.state?.data?.customer_id } });
                }, 1500);
              }else if(response.Status===0){
                toast.error(response.Message)
              }
        } catch (error) {
            
            // Add logic for handling login failure (e.g., show error message)
        }
    };

    const resendOtp = async () => {
        setLoader(true);
        startCountdown();
        try {
            const response = await ResendPaswordOTP(location?.state?.data?.customer_id);
            if(response.Status===1){
                toast.success(response.Message)
              }else if(response.Status===0){
                toast.error(response.Message)
              }
        } catch (error) {
            
            // Add logic for handling login failure (e.g., show error message)
        }
    };

    const activer = () => {
        setSelectedLink('');
        setError('');
        setLoader(false);
    };
    const handleInputChange = (e) => {
        // Allow only numeric input
        const value = e.target.value.replace(/[^0-9]/g, '');
        e.target.value = value;

        // Your additional logic if needed
    };
  return (
    <>
    <div className="signin forgot OTPs">
        <div className="authform">
            <div>
                <div className="vrfy-otp"></div>
                <AuthTitle title={title} />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form">
                        <label>
                            OTP
                            <input
                                onFocus={activer}
                                maxLength="6"
                                onInput={handleInputChange}
                                name='otp'
                                {...register("otp")}
                            />
                            <div className="error">{errors.otp ? errors.otp.message : ''}</div>
                        </label>
                        <button type='submit'>Verify & Proceed</button>
                        {resendDisabled ? (
                            <span style={{ fontSize: '12px', textAlign: 'center', width: '100%', display: 'block', marginTop: 30 }}>
                                Resend OTP in {countdown} seconds
                            </span>
                        ) : (
                            <button className="resend" onClick={resendOtp} disabled={resendDisabled}>
                                Resend OTP
                            </button>
                        )}
                    </div>
                </form>
            </div>
        </div>
        <img src={pswd} className="pos-image-auth" alt="Password" />
    </div>
    
</>
  )
}

export default ResendVerifyOtp
