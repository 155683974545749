import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import store from "../../assets/images/store.png";
import gplay from "../../assets/images/googleply.png";
import sendar from "../../assets/images/sendar.png";
import FooterMenu from './FooterMenu';
import logo from "../../assets/images/footer.png";
import { SubscribeNewsLetter } from '../../services/ApiServices';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AppFooter = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');

  useEffect(() => {
  }, [dispatch]);

  const handleSubscribe = async () => {
    try {
      const response = await SubscribeNewsLetter({
        email: email,
      });
      if (response.Status == 1) {
        toast.success(response.Message)
        setEmail('');
      }
    } catch (error) {
      
    }
  };

  return (
    <>
      <div id="Footer">
        <div className="footer-main">
          <img alt="Vue logo" src={logo} />
          <div className="footer-menu wrapper">
            <div className="menu-f">
              <FooterMenu title="Company" menu={[
                { title: 'About Us', link: 'about-us' },
                { title: 'Careers', link: 'careers' },
                { title: 'Book Now', link: 'services' },
                { title: 'Contact Us', link: 'contact-us' }
              ]} />
              <FooterMenu title="Customer Policies" menu={[
                { title: 'Privacy Policy', link: 'privacy-policy' },
                { title: 'Terms & Conditions', link: 'terms-conditions' }
              ]} />
            </div>
            <div className="mail-s">
              <div className="menu_footer_block">
                <h3>Stay Always In Touch</h3>
                <div className="sub_sc">
                  <input placeholder=" Enter your email address" value={email} onChange={(e) => setEmail(e.target.value)} />
                  <img src={sendar} alt="Send" onClick={handleSubscribe} />
                </div>
                <div className="social">
                  <a href={'*'} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebookF} /></a>
                  <a href={'*'} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
                  <a href={'*'} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} /></a>
                  <a href={'*'} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faWhatsapp} /></a>
                </div>

                <div className="store-ply">
                  <a href={''} target="_blank" rel="noopener noreferrer">
                    <img src={gplay} alt="Google Play" className="gplay" />
                  </a>
                  <a href={''} target="_blank" rel="noopener noreferrer">
                    <img src={store} alt="App Store" className="gplay" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bt">
          Copyright © 2024 ZimpleQ All rights reserved
        </div>
      </div>
      
    </>
  );
};

export default AppFooter;