import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import 'react-tabs/style/react-tabs.css';
import tick from '../../assets/images/tick.jpg'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchserviceProvDetail } from "../../store/ServiceproviderDetail";
import { fetchAvailabilityData } from "../../store/AvailabilitySearch";
import { addFavorite, removeFavorite } from "../../services/ApiServices";
import { toast } from "react-toastify";
import { setAppoinmentDate, setselectedSlot } from "../../store/FilterSliceList";
import { fetchAvailableSlotByid } from "../../store/AvailableSlot";

const ProfileDetail = () => {
    const id = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { userInfo } = useSelector((state) => state.userDetails);
    const { loading, viewserviceprovider } = useSelector((state) => state.viewDetail);
    const { availableSlots, sloteMsg } = useSelector((state) => state.availableSlots);
    const { appoinmentDate } = useSelector((state) => state.filterdata_list);
    const [calender, setcalender] = useState(false)
    const [dates, setDate] = useState(new Date());
    const [value, onChange] = useState();
    const [selectSlot, setselectSlot] = useState(null)
    const [isdate, setisdate] = useState(false)
    const [customdate, setcustomdate] = useState(new Date());
    const [selectedDate, setselectedDate] = useState();
    const [selectedDate1, setselectedDate1] = useState();
    const today = new Date();
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, [id]);
    useEffect(() => {
        dispatch(fetchserviceProvDetail({ id, userInfo }))
        getAvailableSlot({
            id: id?.id,
            date: formattedDate
        })

    }, [id, userInfo])


    const handleCalender = () => {
        setcalender(!calender)
    }

    const currentDate = new Date(appoinmentDate);

    const options0 = { day: 'numeric', month: 'short' };
    const options = { day: 'numeric', month: 'short', year: "numeric" };
    const options2 = { weekday: 'long' };
    const options3 = { month: 'short', day: 'numeric', year: 'numeric' }

    const formattedDate = currentDate?.toLocaleDateString('en-US', options);
    const nextDay = new Date(currentDate);
    nextDay.setDate(currentDate.getDate() + 1);

    const formattedDate2 = nextDay?.toLocaleDateString('en-US', options);



    const checktoday = currentDate.toLocaleDateString('en-US', options3);
    const checkistoday = today.toLocaleDateString('en-US', options3);

    const getAvailableSlot = async (data) => {

        const currentDate = new Date(data?.date);
        // Extract day, month, and year from the currentDate object
        let day = currentDate.getDate();
        let month = currentDate.getMonth() + 1; // Note: January is 0
        let year = currentDate.getFullYear();

        // Format day, month, and year to have leading zeros if necessary
        day = String(day).padStart(2, '0');
        month = String(month).padStart(2, '0');
        const formattedDate1 = currentDate?.toLocaleDateString('en-US', options);

        setselectedDate1(formattedDate1)
        // Create the formatted date string in "dd-mm-yyyy" format
        let formattedDate = `${day}-${month}-${year}`;
        setselectedDate(formattedDate)
        dispatch(fetchAvailabilityData({
            id: data?.id,
            date: formattedDate
        }));

    }

    const renderButtonGroupOutside = ({ currentSlide, totalSlides, goToSlide }) => {
        return (
            <div>
                <button onClick={() => goToSlide(currentSlide - 1)}>Previous</button>
                <button onClick={() => goToSlide(currentSlide + 1)}>Next</button>
            </div>
        );
    };
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    const handleSelectSlot = (id, avail, exp) => {
        
        if (avail == 1 && exp==0) {
            setselectSlot(id)
        }
    }
    const handlesetDate = (date) => {
        const dateObject = new Date(date);
        setisdate(true)
        const formattedcustomDate = dateObject.toLocaleDateString('en-US', options0);
        setDate(date)
        setcalender(!calender)
        setcustomdate(formattedcustomDate)
        getAvailableSlot({
            id: id?.id,
            date: date
        })

    }
    const handleCustomSlot = () => {
        if (isdate) {
            setselectSlot()
            getAvailableSlot({
                id: id?.id,
                date: dates
            })
        }
    }
    const handleAddfav = async () => {
        try {
            const response = await addFavorite({ id: id?.id, userInfo: userInfo });

            if (response.Status == 1) {
                toast.success(response.Message)
                dispatch(fetchserviceProvDetail({ id, userInfo }))
            }
        } catch (error) {

        }
    }
    const handleremFav = async () => {
        try {
            const response = await removeFavorite({ id: id?.id, userInfo: userInfo });
            if (response.Status == 1) {
                toast.success(response.Message)
                dispatch(fetchserviceProvDetail({ id, userInfo }))
            }
        } catch (error) {

        }

    }
    const handlebooking = () => {
        if (selectSlot) {
            dispatch(setAppoinmentDate(selectedDate1))
            dispatch(setselectedSlot({ id: selectSlot, date: selectedDate1 }))
            navigate('/booking-detail/' + id?.id)
            dispatch(fetchAvailableSlotByid({ id: selectSlot, date: selectedDate }))
        } else {
            toast.warn("Please select a slot")
        }
    }
    return (
        <>
            <div className="detail-main">
                <div className="wrapper">
                    <div className="row">
                        <div className="col-12 col-lg-5">
                            <div className="profile-individual">
                                <div className="img_round_bx">
                                    <div className="image_round">
                                        <img src={viewserviceprovider?.service_provider?.service_provider_photo_url} className="img"></img>
                                        <img src={tick} className="tick-svg"></img>
                                    </div>
                                    <div className="div">
                                        <div className="row" style={{ alignItems: "center" }}>
                                            <div className="col-6">
                                                <div className="round-info">
                                                    <h2>{viewserviceprovider?.service_provider?.service_provider_name}</h2>
                                                    <p>{viewserviceprovider?.service_provider?.category?.category_name}</p>
                                                    <p>
                                                    {viewserviceprovider?.service_provider?.specialty?.speciality_name}</p>
                                                    {viewserviceprovider?.service_provider?.provider_type == 3 &&
                                                     <p>{viewserviceprovider?.service_provider?.firm_name}</p>
                                                    }
                                                    <div className="locationdetail"><p> 
                                                        
                                                        <i className="fa-solid fa-location-dot"></i>{viewserviceprovider?.service_provider?.service_provider_location}, {viewserviceprovider?.service_provider?.city_name}</p></div>
                                                </div>
                                            </div>
                                            {viewserviceprovider?.service_provider?.provider_type == 3 ?
                                                <></> :
                                                <div className="col-6" style={{ display: "flex", justifyContent: "flex-end" }}>
                                                    {viewserviceprovider?.service_provider?.favorite == 1 ?
                                                        <button className="user-wishlist" onClick={handleremFav}><i className="fa-solid fa-heart" style={{ paddingRight: "7px" }}></i> Remove from Fav </button>
                                                        :
                                                        <button className="user-remwishlist" onClick={handleAddfav}><i className="fa-solid fa-heart" style={{ paddingRight: "7px" }}></i> Add to Fav </button>
                                                    }
                                                </div>}

                                        </div>

                                    </div>

                                    <div className="detail-map">
                                        <iframe src={`https://www.google.com/maps/embed/v1/place?q=${viewserviceprovider?.service_provider?.service_provider_latitude},${viewserviceprovider?.service_provider?.service_provider_longitude}&key=AIzaSyBTCvNcsYo-DUA3bbtJJkQ_FHm-0y4eeWQ`}
                                            allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" style={{ border: "0", borderRadius: "5px", height: "250px", objectFit: "cover", width: "100%" }}></iframe></div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-12 col-lg-7">
                            <div className="profile-individual">
                                <h2>Book Your Appointment Now</h2>
                                <div className="rate">Booking Charge : ₹{viewserviceprovider?.service_provider?.booking_charge} / Person</div>
                                <Tabs>
                                    <TabList>
                                        <Tab onClick={() => getAvailableSlot({
                                            id: id?.id,
                                            date: formattedDate
                                        })}> {checktoday == checkistoday ? "Today" : currentDate?.toLocaleDateString('en-US', options2)} <br />
                                            <span>{currentDate?.toLocaleDateString('en-US', options0)}</span>
                                        </Tab>
                                        <Tab onClick={() => getAvailableSlot({
                                            id: id?.id,
                                            date: formattedDate2
                                        })}> {checktoday == checkistoday ? "Tommorow" : nextDay?.toLocaleDateString('en-US', options2)} <br />
                                            <span>{nextDay?.toLocaleDateString('en-US', options0)}</span>
                                        </Tab>
                                        <Tab onClick={handleCustomSlot}>
                                            More date <br />
                                            <span onClick={handleCalender} style={{ cursor: "pointer" }}>{isdate ? customdate : "See Calendar"}</span>
                                        </Tab>

                                    </TabList>
                                    {calender && (
                                        <div className='calender-position'>
                                            <div className='calendar-container'>
                                                <Calendar onChange={handlesetDate} value={dates} minDate={today} />
                                            </div>
                                            <p className='text-center'>
                                            </p>
                                        </div>
                                    )}
                                    {availableSlots ?
                                        <div className="row large-2">
                                            <div className="row">
                                                {availableSlots?.map((slot, index) => {
                                                    const date = new Date(`2000-01-01T${slot.time_start}`);
                                                    const formattedStart = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

                                                    const date2 = new Date(`2000-01-01T${slot.time_end}`);
                                                    const formattedEnd = date2.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });


                                                    return (
                                                        <div className="col col-lg-4 col-md-4 col-sm-6" key={index} >
                                                            <div className={slot.is_expired == 1 ? "availblae-active-checktime" :
                                                                slot.is_available == 0 ? "availblae-active-disactive" :
                                                                    slot.id === selectSlot ? "availblae" : "availblae-active"}
                                                                onClick={() => handleSelectSlot(slot.id, slot.is_available, slot.is_expired)} >
                                                                {formattedStart} - {formattedEnd}<br />
                                                                {slot.remaining_count} Available
                                                            </div>
                                                        </div>

                                                    )
                                                })}
                                            </div>
                                        </div> :
                                        <div className="row large-2" >
                                            <div className='col-12' style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <p>{sloteMsg}</p>
                                            </div>
                                        </div>
                                    }

                                    <div className="buttons_boo nobordr">
                                        <button className="cancel_bkn" onClick={()=>navigate('/services')}>Cancel</button>
                                        <button className={selectSlot?"schedule_bkng":"schedule_bkng-disable"} onClick={handlebooking} disabled={selectSlot? false: true}> Book Now </button>
                                    </div>
                                    <p className="tc_d">Note: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>

                                </Tabs>
                            </div>

                        </div><div className="related_profiles">
                            <h2>Related Profiles</h2>
                        </div>
                        {viewserviceprovider?.related_profiles?.length > 0 &&
                            <Carousel responsive={responsive} arrows={false} showDots={true} renderDotsOutside={renderButtonGroupOutside}>
                                {viewserviceprovider?.related_profiles?.map((obj) => {
                                    return (
                                        <div className="single_profile related-single" key={obj?.id} onClick={() => navigate("/view-profile/" + obj.id)}>
                                            <div className="profile_img">
                                                <img src={obj.service_provider_photo_url} /></div>
                                            <div className="profile_det" >
                                                <h3>{obj.service_provider_name}</h3><span className="dept">{obj.service_provider_designation}</span>
                                                <p className="exp_adrsp">{obj.service_provider_location}</p><p className="shrt-des">{obj.service_provider_organisation}</p>
                                                <p className="shrt-des">₹{obj.consultation_fee}</p></div>
                                        </div>
                                    )
                                })}
                            </Carousel>
                        }

                    </div>
                </div>
            </div>

        </>
    )
}

export default ProfileDetail

