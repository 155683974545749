import React, { useState } from 'react';
import AuthTitle from './AuthTitle';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import pswd from "../../assets/images/pswd.png";
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ResetPassword } from '../../services/ApiServices';

const ForgotReset = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const title = 'Reset Password';
    const [type, setType] = useState('password');
    const [btnText, setBtnText] = useState('fa-eye-slash');
    const passwordRegex = /^(?=.*[^a-zA-Z0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
    const schema = yup.object().shape({
        password: yup
            .string()
            .required("Password required")
            .min(8, "Passwords must be 8 characters or more")
            .max(20, "Max characters limit Reached")
            .matches(
                passwordRegex,
                "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character"
            ),
        ConfirmPassword: yup
            .string()
            .required("Confirm Password required")
            .oneOf([yup.ref("password"), null], "Passwords must match"),
    })
    const {
        register,
        handleSubmit,
        reset,
        clearErrors,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const onSubmit = async (data) => {
        try {
            const response = await ResetPassword({
                customer_id: location.state.customerid,
                password: data?.password,
                confirm: data?.ConfirmPassword
            });
            if (response.Status === 1) {
                toast.success(response.Message)
                setTimeout(() => {
                    navigate('/sign-in', { state: { data: response.Data } });
                }, 1500);
            } else if (response.Status === 0) {
                toast.error(response.Message.customer_mobile ? response.Message.customer_mobile[0] : response.Message.customer_email[0])
            }
        } catch (error) {

            // Add logic for handling login failure (e.g., show error message)
        }
    }
    const showPassword = () => {
        if (type === 'password') {
            setType('text');
            setBtnText('fa-eye');
        } else {
            setType('password');
            setBtnText('fa-eye-slash');
        }
    };

    return (
        <>
            <div className="signin forgot">
                <div className="authform">
                    <div>
                        <AuthTitle title={title} />
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form">
                                <label>
                                    Password <b style={{ color: 'red' }}>*</b>
                                    <span style={{ position: 'relative' }} className="eye">
                                        <input
                                            type={type}
                                            autoComplete="new-password"
                                            name='password'
                                            {...register("password")}
                                        />
                                        <button className="tg-ps" type='button' onClick={showPassword}>
                                            <FontAwesomeIcon icon={btnText === 'fa-eye' ? faEye : faEyeSlash} />
                                        </button>
                                    </span>
                                    <div className="error">{errors.password ? errors.password.message : ''}</div>
                                </label>
                                <label>
                                    Confirm Password <b style={{ color: 'red' }}>*</b>
                                    <span style={{ position: 'relative' }} className="eye">
                                        <input
                                            type={type}
                                            autoComplete="new-password"
                                            name='ConfirmPassword'
                                            {...register("ConfirmPassword")}
                                        />
                                        <button className="tg-ps" type='button' onClick={showPassword}>
                                            <FontAwesomeIcon icon={btnText === 'fa-eye' ? faEye : faEyeSlash} />
                                        </button>
                                    </span>
                                    <div className="error">{errors.ConfirmPassword ? errors.ConfirmPassword.message : ''}</div>
                                </label>
                                <button type='submit'>Continue</button>
                            </div>
                        </form>
                    </div>
                </div>
                <img src={pswd} className="pos-image-auth" alt="Password" />
            </div>

        </>
    );
};

export default ForgotReset;
