import React from 'react';
import { Link } from 'react-router-dom';
import book from "../../assets/images/booknow.png";

const BookNowhome = () => {
    return (
        <div className="wrapper">
            <div className="book_area">
                <img src={book} alt="Book Now" className="all_b" />

                <div>
                    <h3>MAKE AN APPOINTMENT</h3>
                    <p>Quisque lacinia dolor vitae nulla ccumsan consectetur. Aliquamsollicitudin purus liquam.</p>
                    <Link to={'/services'}>
                        <button>MAKE AN APPOINTMENT</button>
                    </Link>
                </div>
                {/* <img src={require('@/assets/images/bg3.png')} alt="Background" className="bg-book" /> */}
                <svg
                    width={646}
                    height={603}
                    viewBox="0 0 646 603"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.95503 -95.4334C85.3179 -323.7 276.824 -61.6749 452.105 -67.7599C608.006 -73.1722 919.987 -32.9685 959.044 146.083C665.795 452.542 853.481 628.918 678.088 770.766C517.905 900.312 670.511 468.65 542.741 347.52C417.958 229.22 -63.2485 114.659 7.95503 -95.4334Z"
                        fill="url(#paint0_linear_283_871)"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_283_871"
                            x1={53}
                            y1={466}
                            x2={464.169}
                            y2={-418.201}
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#FF2354" />
                            <stop offset={0.514108} stopColor="#FF4A2C" />
                            <stop offset={1} stopColor="#FF8064" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
        </div>
    );
};

export default BookNowhome;
