import React from 'react';
import s1 from "../../assets/images/s1.png";
import ic1 from "../../assets/images/ic1.png";
import ic2 from "../../assets/images/ic2.png";
import ic3 from "../../assets/images/ic3.png";
import ic4 from "../../assets/images/ic4.png";

const BookingStep = () => {
  return (
    <div className="booking_step">
      <h3>STEPS TO GET YOUR SERVICE</h3>

      <div className="steps">
        <div className="step">
          <div>
            <img src={s1} alt="Step 1" />
            <img src={ic1} className="pos-img" alt="Icon 1" />
          </div>
          <p>Find Your Service</p>
        </div>
        <div className="step">
          <div>
            <img src={s1} alt="Step 2" />
            <img src={ic3} className="pos-img" alt="Icon 3" />
          </div>
          <p>Choose Your Location</p>
        </div>
        <div className="step">
          <div>
            <img src={s1} alt="Step 3" />
            <img src={ic2} className="pos-img" alt="Icon 2" />
          </div>
          <p>Schedule Your Appointment</p>
        </div>
        <div className="step">
          <div>
            <img src={s1} alt="Step 4" />
            <img src={ic4} className="pos-img" alt="Icon 4" />
          </div>
          <p>Get Your Appointment</p>
        </div>
      </div>
    </div>
  );
};

export default BookingStep;
