import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FilterCategory, FilterSubCategory, FilterSpecialitty } from "../services/ApiServices";

export const fetchCategoryDatas = createAsyncThunk(
  "filters/fetchCategoryDatas",
  async (params) => {
    const data = await FilterCategory();

    return data.Data;
  }
);

export const fetchSubcategoryDatas = createAsyncThunk(
  "filters/fetchSubcategoryDatas",
  async (params) => {
    try {
      const data = await FilterSubCategory(params);
      return data.Data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchSpecialityDatas = createAsyncThunk(
  "filters/fetchSpecialityDatas",
  async (params) => {
    try {
      const data = await FilterSpecialitty(params);
      return data.Data;
    } catch (error) {
      throw error;
    }
  }
);

const FilterSliceList = createSlice({
  name: "FilterSearch",
  initialState: {
    category_filter: [],
    categoryData: [],
    firmcategoryData:[],
    subcategory_filter: [],
    subcategoryData: [],
    speciality_filter: [],
    specialityData: [],
    sortBy:'',
    appoinmentDate:null,
    loading: false,
    error: null,
    selectSlot:''
  },
  reducers: {
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setSelectedSubCategory: (state, action) => {
      state.selectedSubCategory = action.payload;
    },
    setSelectedSpeciality: (state, action) => {
      state.selectedSpeciality = action.payload;
    },
    setDistance: (state, action) => {
      state.searchDistance = action.payload;
    },
    setAppoinmentDate: (state, action) => {
      state.appoinmentDate = action.payload;
    },
    setProviderType: (state, action) => {
      state.providerType = action.payload;
    },
    setSortBy: (state,action) =>{
      state.sortBy = action.payload;
    },
    setselectedSlot: (state,action) =>{
      state.selectedSlot = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategoryDatas.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCategoryDatas.fulfilled, (state, action) => {
  
        state.category_filter = action.payload;
        const transformedData = action.payload
          ?.filter((category) => category.provider_type !== 2) // Filter out provider type 2 data
          .map((category) => ({
            label: category.category_name,
            value: category.id,
          }));
        state.categoryData = transformedData;
        const transformedData2 = action.payload
          ?.filter((category) => category.provider_type !== 1) // Filter out provider type 2 data
          .map((category) => ({
            label: category.category_name,
            value: category.id,
          }));
        state.firmcategoryData = transformedData2;
        state.loading = false;
      })
      .addCase(fetchCategoryDatas.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchSubcategoryDatas.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSubcategoryDatas.fulfilled, (state, action) => {
        state.subcategory_filter = action.payload;
        const transformedData = action.payload?.map((subcategory) => ({
          label: subcategory.category_name,
          value: subcategory.id,
        }));
        state.subcategoryData = transformedData;
        state.loading = false;
      })
      .addCase(fetchSubcategoryDatas.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchSpecialityDatas.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSpecialityDatas.fulfilled, (state, action) => {
        state.speciality_filter = action.payload;
        const transformedData = action.payload?.map((speciality) => ({
          label: speciality.speciality_name,
          value: speciality.id,
        }));
        state.specialityData = transformedData;
        state.loading = false;
      })
      .addCase(fetchSpecialityDatas.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  setSelectedCategory,
  setSelectedSubCategory,
  setSelectedSpeciality,
  setDistance,
  setAppoinmentDate,
  setProviderType,
  setSortBy,
  setselectedSlot
} = FilterSliceList.actions;

export default FilterSliceList.reducer;
