import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getKycList } from "../services/ApiServices"


export const fetchkyclist = createAsyncThunk(
  "filters/fetchkyclist",
  async () => {
  
    const data = await getKycList();
        return data.Data;
      }
    );
const listKyc = createSlice({
  name: "kyc",
  initialState: {
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchkyclist.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchkyclist.fulfilled, (state, action) => {
        state.Kyclist=action.payload;
        state.loading = false;
      })
      .addCase(fetchkyclist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { 
 } = listKyc.actions;

export default listKyc.reducer;