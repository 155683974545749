import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import 'react-tabs/style/react-tabs.css';
import tick from '../../assets/images/tick.jpg'
import 'react-calendar/dist/Calendar.css';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchserviceProvDetail } from "../../store/ServiceproviderDetail";
import { fetchAvailabilityData } from "../../store/AvailabilitySearch";
import { addFavorite, removeFavorite } from "../../services/ApiServices";
import { toast } from "react-toastify";
import { fetchfirmMemberList } from "../../store/FirmMemberlist";
import ListingCard from "../service/ListingCard";
import Vector from "../../components/service/assets/Images/Vector.svg";

const FirmDetail = () => {
    const id = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { userInfo } = useSelector((state) => state.userDetails);
    const { loading, viewserviceprovider } = useSelector((state) => state.viewDetail);
    const { listfirmmember } = useSelector((state) => state.firmmember);
    const { appoinmentDate } = useSelector((state) => state.filterdata_list);

    useEffect(() => {
        dispatch(fetchserviceProvDetail({ id, userInfo }))
        dispatch(fetchfirmMemberList({ id: id?.id, userInfo }))
        getAvailableSlot({
            id: id?.id,
            date: formattedDate
        })
    }, [id, userInfo])
    const currentDate = new Date(appoinmentDate);
    const options = { day: 'numeric', month: 'short', year: "numeric" };
    const formattedDate = currentDate?.toLocaleDateString('en-US', options);


    const getAvailableSlot = async (data) => {
    
        const currentDate = new Date(data?.date);
        // Extract day, month, and year from the currentDate object
        let day = currentDate.getDate();
        let month = currentDate.getMonth() + 1; // Note: January is 0
        let year = currentDate.getFullYear();

        // Format day, month, and year to have leading zeros if necessary
        day = String(day).padStart(2, '0');
        month = String(month).padStart(2, '0');

        // Create the formatted date string in "dd-mm-yyyy" format
        let formattedDate = `${day}-${month}-${year}`;

        dispatch(fetchAvailabilityData({
            id: data?.id,
            date: formattedDate
        }));

    }
    const renderButtonGroupOutside = ({ currentSlide, goToSlide }) => {
        return (
            <div>
                <button onClick={() => goToSlide(currentSlide - 1)}>Previous</button>
                <button onClick={() => goToSlide(currentSlide + 1)}>Next</button>
            </div>
        );
    };
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
  
    const handleAddfav = async () => {
        try {
            const response = await addFavorite({ id: id?.id, userInfo: userInfo });
           
            if (response.Status == 1) {
                toast.success(response.Message)
                dispatch(fetchserviceProvDetail({ id, userInfo }))
            }
        } catch (error) {
            
        }
    }
    const handleremFav = async () => {
        try {
            const response = await removeFavorite({ id: id?.id, userInfo: userInfo });
           
            if (response.Status == 1) {
                toast.success(response.Message)
                dispatch(fetchserviceProvDetail({ id, userInfo }))
            }
        } catch (error) {
            
        }

    }
    return (
        <>
            <div className="detail-main">
                <div className="wrapper">
                    <div className="row">
                        <div className="col-12 col-lg-5">
                            <div className="profile-individual">
                                <div className="img_round_bx">
                                    <div className="image_round">
                                        <img src={viewserviceprovider?.service_provider?.service_provider_photo_url} className="img"></img>
                                        <img src={tick} className="tick-svg"></img>
                                    </div>
                                    <div className="div">
                                        <div className="row" style={{ alignItems: "center" }}>
                                            <div className="col-6">
                                                <div className="round-info">
                                                    <h2>{viewserviceprovider?.service_provider?.service_provider_name}</h2>
                                                    <div className="locationdetail"><p> <i className="fa-solid fa-location-dot"></i>{viewserviceprovider?.service_provider?.service_provider_address}</p></div>
                                                </div>
                                            </div>
                                            <div className="col-6" style={{ display: "flex", justifyContent: "flex-end" }}>
                                                {viewserviceprovider?.service_provider?.favorite == 1 ?
                                                    <button className="user-wishlist" onClick={handleremFav}><i className="fa-solid fa-heart" style={{ paddingRight: "7px" }}></i> Remove from Fav </button>
                                                    :
                                                    <button className="user-remwishlist" onClick={handleAddfav}><i className="fa-solid fa-heart" style={{ paddingRight: "7px" }}></i> Add to Fav </button>
                                                }
                                            </div>
                                        </div>

                                    </div>

                                    <div className="detail-map">
                                        <iframe src={`https://www.google.com/maps/embed/v1/place?q=${viewserviceprovider?.service_provider?.service_provider_latitude},${viewserviceprovider?.service_provider?.service_provider_longitude}&key=AIzaSyBTCvNcsYo-DUA3bbtJJkQ_FHm-0y4eeWQ`}
                                            allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" style={{ border: "0", borderRadius: "5px", height: "250px", objectFit: "cover", width: "100%" }}></iframe></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-7">
                            <div className=" row margin-change">
                                <h2>Members</h2>
                                {listfirmmember?.length > 0 ? (
                                    listfirmmember?.map((provider, index) => (
                                        <ListingCard key={index} data={provider} />
                                    ))
                                ) : <div className='no_data_msg'>
                                    <p>Sorry, we couldn't find any matching results for your search at the moment.!</p>
                                </div>}
                            </div>


                        </div>
                        {/* retaled profile */}
                        <div className="related_profiles">
                            <h2>Related Profiles</h2>
                        </div>

                        <Carousel responsive={responsive} arrows={false} showDots={true} renderDotsOutside={renderButtonGroupOutside}>
                            {viewserviceprovider?.related_profiles?.map((obj) => {
                                return (

                                    <div className="single_profile related-single" key={obj?.id} onClick={() => navigate("/view-profile/" + obj.id)}>
                                        <div className="profile_img">

                                            <img src={obj?.service_provider_photo_url} /></div>
                                        <div className="profile_det" >

                                            <h3>{obj?.service_provider_name}</h3><span className="dept">{obj?.category?.category_name}</span>
                                           
                                            <div className="row">
                                <div className="col-1" style={{ paddingRight: 0 }}>
                                    <img src={Vector} className="location" />
                                </div>
                                <div className="col-11 shrt-des">{obj?.location?.location_name}, {obj?.city?.city_name}</div>
                                {/* <div className="col-6 Booking-num">{distance?.toFixed(2)} Km</div> */}
                            </div>
                                            </div>
                                    </div>
                                )
                            })}

                        </Carousel>
                    </div>
                </div>
            </div>

        </>
    )
}

export default FirmDetail
