import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import "./asset/Account.css"
import "./asset/Accountresponsive.css"
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { fetchaccountlist } from '../../store/Accountlist';
import { useForm } from 'react-hook-form';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { getBloodgrouplist, getcitylist, getcountrylist, getlocationlist, updateMyAccount } from '../../services/ApiServices';
import dayjs from 'dayjs';
import AccountSideBar from './AccountSideBar';
import moment from 'moment';
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loaders from '../Loader/Loaders';
import { setusername } from '../../store/userSlice';

const AccountForm = () => {
    const dispatch = useDispatch()
    const { getAccountDetail,  loading } = useSelector((state) => state.accountlist);

    useEffect(() => {
        dispatch(fetchaccountlist())
        getbloodlist()
        getcountrylists()
    }, [])

    const [bloodlist, setbloodlist] = useState([])
    const [citylist, setcitylist] = useState([])
    const [countrylist, setcountrylist] = useState([])
    const [locationlist, setlocationlist] = useState([])
    const [city, setcity] = useState([])
    const [location, setlocation] = useState([])
    const formattedDate = dayjs(getAccountDetail?.dob, 'DD-MM-YYYY').format('YYYY-MM-DD');
    const [date2, setDate2] = useState(dayjs(formattedDate));
    const [dob, setdob] = useState(dayjs(new Date()));
    const [dobfor, setdobfor] = useState(new Date);
    const emailRegx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const schema = yup.object().shape({
        name: yup.string().required("Name is required"),
        mobile: yup.string().required("Mobile Number is required").max(20),
        email: yup.string().required("Email is required").matches(emailRegx, "Email must be a valid email (eg:-abc@gmail.com)"),
        customer_gender: yup.string().required("Gender is required"),
        dob: yup.string().required("DOB is required"),
        blood_group: yup.string(),
        houseno: yup.string().required("House Number is required"),
        street: yup.string().required("Street is required"),
        city: yup.string().required("City is required"),
        location: yup.string().required("Location is required"),
        pin: yup.string().required("Pincode is required"),
    })
    const { register, handleSubmit, getValues, setValue, setError, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    })

    useEffect(() => {
        if (formattedDate) {
            setValue('dob', dayjs(formattedDate))
        }
        if (getAccountDetail && getAccountDetail.dob) {
            const formattedDate = dayjs(getAccountDetail?.dob, 'DD-MM-YYYY').format('YYYY-MM-DD');
            setDate2(dayjs(formattedDate));
        }
    }, []);

    const handleDateChange2 = (selectedDate) => {
  
        setDate2(selectedDate);
        let dateconvert = moment(selectedDate).format('YYYY-MM-DD')
        setValue("dob", selectedDate)
    };
  
    const RequiredLabel = ({ labelText }) => (
        <div>
            <Typography component="span">{labelText}</Typography>
            <Typography component="span" style={{ color: 'red', fontSize: "20px" }}>
                *
            </Typography>
        </div>
    );
    const handleupdateprofile = async (data) => {
        const dateObj = new Date(date2?.$d);

        const formattedDate = `${String(dateObj.getUTCDate() + 1).padStart(2, '0')}-${String(dateObj.getUTCMonth() + 1).padStart(2, '0')}-${dateObj.getUTCFullYear()}`;
        if (formattedDate === "NaN-NaN-NaN") {
            setError('dob', { type: 'custom', message: "Enter valid date" });
        } else {
            const dataxx = {
                name: data?.name,
                mobile: data?.mobile,
                email: data?.email,
                gender: data?.customer_gender,
                dob: formattedDate === "NaN-NaN-NaN" ? dobfor : formattedDate,
                blood_group: data?.blood_group,
                house_number: data?.houseno,
                street: data?.street,
                country_id: data?.country,
                city_id: data?.city,
                location_id: data?.location,
                pin: data?.pin
            }
            try {
                const response = await updateMyAccount({ dataxx });
                if (response.Status == 1) {
                    toast.success(response.Message)
                    dispatch(setusername(response.Data.customer_name));
                } else {
                    toast.success(response.Message)
                }
            } catch (error) {

                toast.error(error.Message)
            }
        }


    }
    const getbloodlist = async () => {
        try {
            const response = await getBloodgrouplist();

            setbloodlist(response.Data)

        } catch (error) {

        }
    }
    const getcountrylists = async () => {
        try {
            const response = await getcountrylist();

            setcountrylist(response.Data)
            getcitylists()

        } catch (error) {

        }
    }
    const getcitylists = async () => {
        try {
            const response = await getcitylist();
            setcitylist(response.Data)
            getlocationlists(getAccountDetail?.customer_address?.city_id)

        } catch (error) {

        }
    }
    const getlocationlists = async (val) => {
        try {
            const response = await getlocationlist(val);

            setlocationlist(response.Data)

        } catch (error) {

        }
    }
    const handlecitychange = (val) => {
        setcity(val)
        getlocationlists(val)
        setlocation()
        setValue("location", val)
    }
    return (
        <div>
            <Loaders loading={loading} />
            {getAccountDetail && (
                <form onSubmit={handleSubmit(handleupdateprofile)}>
                    <div className="row">
                        <AccountSideBar page="myaccount" />
                        <div className="col-12 col-lg-9">
                            <div className="my-account">
                                <h2>My Account </h2>
                                <div className="row textfield-row-main">
                                    <div className="col-6">
                                        <TextField
                                            id="standard-basic"
                                            label={<RequiredLabel labelText="Name" />}
                                            variant="standard"
                                            defaultValue={getAccountDetail?.customer_name}
                                            name='name'
                                            {...register("name")}
                                        />
                                        <span className="errorspan">{errors.name?.message}</span>
                                    </div>
                                    <div className="col-6">
                                        <TextField
                                            id="standard-basic"
                                            label={<RequiredLabel labelText="Mobile Number" />}
                                            variant="standard"
                                            name='mobile'
                                            {...register("mobile")}
                                            inputProps={{
                                                maxLength: 10
                                            }}
                                            defaultValue={getAccountDetail?.customer_mobile}
                                        />
                                        <span className="errorspan">{errors.mobile?.message}</span>
                                    </div>
                                </div>
                                <div className="row textfield-row-main">
                                    <div className="col-6">
                                        <TextField
                                            id="standard-basic"
                                            label={<RequiredLabel labelText="Email" />}
                                            variant="standard"
                                            name='email'
                                            {...register("email")}
                                            defaultValue={getAccountDetail?.customer_email}
                                        />
                                        <span className="errorspan">{errors.email?.message}</span>
                                    </div>
                                    <div className="col-6 select">
                                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                            <InputLabel id="demo-simple-select-standard-label">{<RequiredLabel labelText="Gender" />}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                name='customer_gender'
                                                {...register("customer_gender")}
                                                defaultValue={getAccountDetail?.customer_gender?.id}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value="1">Male</MenuItem>
                                                <MenuItem value="2">Female</MenuItem>
                                                <MenuItem value="3">Other</MenuItem>
                                            </Select>
                                            <span className="errorspan">{errors.customer_gender?.message}</span>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="row textfield-row-main">
                                    <div className="col-6 datepicker width-change">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                                <DatePicker
                                                    label={<RequiredLabel labelText="Dob" />}
                                                    disableFuture
                                                    value={date2}
                                                    {...register('dob')}
                                                    onChange={handleDateChange2}
                                                />
                                                <div className="errorDetails">{errors.dob ? errors.dob.message : ''}</div>
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                    <div className="col-6 select">
                                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                            <InputLabel id="demo-simple-select-standard-label">Blood Group</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                label={<RequiredLabel labelText="Blood Group" />}
                                                name='blood_group'
                                                {...register("blood_group")}
                                                defaultValue={getAccountDetail?.blood_group?.id}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {bloodlist?.map((obj) => {
                                                    return (
                                                        <MenuItem value={obj.id}>{obj.blood_group_name}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>

                                    </div>
                                </div>
                                <h2 style={{ fontSize: "24px", marginTop: "40px", fontWeight: "500", marginBottom: "10px" }}>Address </h2>
                                <div className="row textfield-row-main">
                                    <div className="col-6">
                                        <TextField
                                            id="standard-basic"
                                            label={<RequiredLabel labelText="House Number" />}
                                            variant="standard"
                                            name='houseno'
                                            {...register("houseno")}
                                            defaultValue={getAccountDetail?.customer_address?.house_number}
                                        />
                                        <span className="errorspan">{errors.houseno?.message}</span>
                                    </div>
                                    <div className="col-6">
                                        <TextField
                                            id="standard-basic"
                                            label={<RequiredLabel labelText="Street" />}
                                            variant="standard"
                                            name='street'
                                            {...register("street")}
                                            defaultValue={getAccountDetail?.customer_address?.street}
                                        />
                                        <span className="errorspan">{errors.street?.message}</span>
                                    </div>
                                </div>

                                <div className="row textfield-row-main">
                                    <div className="col-6 select">
                                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                            <InputLabel id="demo-simple-select-standard-label">{<RequiredLabel labelText="Country" />}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                disabled
                                                name='country'
                                                {...register("country")}
                                                defaultValue={getAccountDetail?.customer_address?.country_id ? getAccountDetail?.customer_address?.country_id : 101}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {countrylist?.map((obj) => {
                                                    return (
                                                        <MenuItem key={obj?.country_id} value={obj?.country_id}>{obj?.country_name}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-6 select">
                                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                            <InputLabel id="demo-simple-select-standard-label">{<RequiredLabel labelText="City" />}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                name='city'
                                                {...register("city")}
                                                onChange={(e) => handlecitychange(e.target.value)}
                                                defaultValue={getAccountDetail?.customer_address?.city_id}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {citylist?.map((obj) => {
                                                    return (
                                                        <MenuItem key={obj?.id} value={obj?.id}>{obj?.city_name}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                            <span className="errorspan">{errors.city?.message}</span>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="row textfield-row-main">
                                    <div className="col-6 select">
                                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                            <InputLabel id="demo-simple-select-standard-label">{<RequiredLabel labelText="Location" />}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                name='location'
                                                {...register("location")}
                                                defaultValue={getAccountDetail?.customer_address?.location_id}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {locationlist?.map((obj) => {
                                                    return (
                                                        <MenuItem key={obj?.id} value={obj?.id}>{obj?.location_name}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                            <span className="errorspan">{errors.location?.message}</span>
                                        </FormControl>
                                    </div>
                                    <div className="col-6">
                                        <TextField id="standard-basic" label={<RequiredLabel labelText="Pincode" />} variant="standard" name='pin'
                                            inputProps={{
                                                maxLength: 6
                                            }}
                                            defaultValue={getAccountDetail?.customer_address?.pin}  {...register("pin")} />
                                        <span className="errorspan">{errors.pin?.message}</span>
                                    </div>
                                </div>
                                <button className="save" type='submit'>Update</button>
                            </div>
                        </div>
                    </div>

                </form>
            )}
        </div>

    )
}

export default AccountForm
