import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppFooter from '../components/Common/AppFooter';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CareerInfo, SubmitCareerForm } from '../services/ApiServices';
import Accordion from 'react-bootstrap/Accordion';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AppHeader from '../components/Common/Header/AppHeader';


const CareerList = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);
    const { info } = useSelector((state) => state.ContactInfo);
    const [isActive, setIsActive] = useState(false);
    const [data, setData] = useState([])
    const [position, setPosition] = useState('')
    const emailRegx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const setId = (ev) => {
    };
    useEffect(() => {
        careersdata()
    }, [])
    const schema = yup.object().shape({
        name: yup.string().required("Name is required"),
        Email: yup
            .string()
            .email()
            .required("Email required")
            .matches(emailRegx, "Email must be a valid email (eg:-abc@gmail.com)"),
        experiance: yup.string().required("Experiance is required"),
        myfile: yup
        .mixed()
        .test('fileType', 'Invalid file format', (value) => {
            return value && value[0] && value[0].type === 'application/pdf';
        })
        .required('Resume is required'),
        send_msg: yup.string().required("Message is required"),
    });
    const { register, handleSubmit, setValue, clearErrors, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    useEffect(() => {
        const handleEscapeKeyPress = (event) => {
            if (event.key === 'Escape') {
                closeFm();
            }
        };
        document.addEventListener('keydown', handleEscapeKeyPress);
        return () => {
            document.removeEventListener('keydown', handleEscapeKeyPress);
        };
    }, []);
    const careersdata = async () => {
        try {
            const response = await CareerInfo();
            if (response.Status === 1) {
                setData(response.Data)
            } else if (response.Status === 0) {
                toast.error(response.Message)
            }
        } catch (error) {
            
            // Add logic for handling login failure (e.g., show error message)
        }
    }
    const onSubmit = async (data) => {
        try {
            const response = await SubmitCareerForm({
                name: data.name,
                email: data.Email,
                position: position,
                experiance: data.experiance,
                file: data.myfile[0],
                message: data.send_msg,
            });
            if(response.Status==1){
                toast.success(response.Message)
                setIsActive(false);
            }
        } catch (error) {
            
        }
    }
    const closeFm = () => {
        setIsActive(false);
    };
    const applynowform = (id) => {
        setIsActive(true);
        setPosition(id)
    }
    return (
        <>
            <div className="inner_page_layout common_page">
                <AppHeader />
                <div className="layout-pg">
                    <div className="content_pg">
                        <div className="wrapper">
                            <div className="abt_flex">
                                <div className="co_abt">
                                    <h2>Work With Us</h2>
                                    <p>{data?.description}</p>
                                </div>
                                <img src={data?.image_url} alt="Career" className="careerimg" />
                            </div>
                            <div className="openings">
                                <h2>Current Openings</h2>
                                <Accordion defaultActiveKey="0">
                                    {data?.openings?.map((career, i) => (
                                        <Accordion.Item key={i} eventKey={i}>
                                            <Accordion.Header>{career.title}</Accordion.Header>
                                            <Accordion.Body>
                                                <div className="cInfo">
                                                    <span className="infoExp">
                                                        <p><span><b>Experience:</b></span> {career.experiance}</p>
                                                    </span>
                                                    <div className="des_exp">{career.description}</div>
                                                    <button onClick={() => applynowform(career?.id)}>Apply Now</button>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>))}
                                </Accordion>
                                <div className={`applyForm ${isActive ? 'show_form' : ''}`}>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <button id="close_cf" onClick={closeFm} type="button">
                                            X
                                        </button>
                                        <div className="field_career">
                                            <label>Name</label>
                                            <input
                                                type="text"
                                                name='name'
                                                {...register("name")}
                                            />
                                            <div className="error">{errors.name ? errors.name.message : ''}</div>
                                        </div>
                                        <div className="field_career">
                                            <label>Email</label>
                                            <input
                                                type="email"
                                                name='Email'
                                                {...register("Email")}
                                            />
                                            <div className="error">{errors.Email ? errors.Email.message : ''}</div>
                                        </div>
                                        <div className="field_career">
                                            <label>Position Applied For</label>
                                            <select value={position} onChange={(e) => setPosition(e.target.value)}
                                            >
                                                {data?.openings?.map((career) => (
                                                    <option key={career.id} value={career.id}>
                                                        {career.title}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="field_career">
                                            <label>Years of Experience</label>
                                            <input
                                                type="text"
                                                name='experiance'
                                                {...register("experiance")}
                                            />
                                            <div className="error">{errors.experiance ? errors.experiance.message : ''}</div>
                                        </div>
                                        <div className="field_career">
                                            <label>Upload Resume</label>
                                            <input
                                                type="file"
                                                accept=".pdf, application/pdf"
                                                name="myfile"
                                                onChange={(e) => {
                                                    const file = e.target.files[0];
                                                    setValue('myfile', file);
                                                    clearErrors('myfile');
                                                }}
                                                {...register('myfile')}
                                            />
                                            <div className="error">{errors.myfile?.message}</div>
                                        </div>
                                        <div className="field_career">
                                            <label>Message</label>
                                            <textarea
                                                name='send_msg'
                                                {...register("send_msg")}
                                            />
                                            <div className="error">{errors.send_msg ? errors.send_msg.message : ''}</div>
                                        </div>
                                        <div className="field_career">
                                            <button type="submit">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AppFooter />
            </div>
            
        </>
    );
};

export default CareerList;
