import React, { useEffect,useState } from 'react';
import AppFooter from '../components/Common/AppFooter';
import { getTermsandconditionpage } from '../services/ApiServices';
import AppHeader from '../components/Common/Header/AppHeader';


const PrivacyPolicy = () => {
    const [data,setData] = useState([])
    useEffect(() => {
      getaboutus()
      window.scrollTo(0, 0); 
    }, []);
    const getaboutus = async ()  =>{
      try {
          const response = await getTermsandconditionpage();
          setData(response.data)
      } catch (error) {
          
      }
    }
 
    return (
        <div className="list_page_layout">
            <AppHeader />
            <div className="wrapper">
                <div className="privcyPolicy">
                    <h2 className='mt-5'>Privacy Policy</h2>
                    <div dangerouslySetInnerHTML={{ __html: data?.description }}></div>
                </div>
            </div>
            <AppFooter />
        </div>
    );
};

export default PrivacyPolicy;
