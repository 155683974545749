import React, { useState } from 'react';
import check from "../../components/service/assets/Images/Check circle.svg";
import Vector from "../../components/service/assets/Images/Vector.svg";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAvailabilityData } from '../../store/AvailabilitySearch';
import { setselectedSlot } from '../../store/FilterSliceList';
import { fetchAvailableSlotByid } from '../../store/AvailableSlot';


function ListingCard({ data }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [value, setValue] = useState();
    const [selectSlot, setselectSlot] = useState();
    const [showCalendar, setShowCalendar] = useState(false);
    const { availableSlots, sloteMsg } = useSelector((state) => state.availableSlots);
    const [show, setShow] = useState(false)
    const [defaultindex, setdefaultindex] = useState(false)
    const [providerId, setProviderId] = useState('')
    const [customdate, setcustomdate] = useState()
    const {
        appoinmentDate,
        selectSlot_id
    } = useSelector((state) => state.filterdata_list);
    const handleButtonClick = (data) => {
        setdefaultindex(2)
        setShowCalendar(!showCalendar)
        const currentDate = new Date(data?.date);
        // Extract day, month, and year from the currentDate object
        let day = currentDate.getDate();
        let month = currentDate.getMonth() + 1; // Note: January is 0
        let year = currentDate.getFullYear();

        // Format day, month, and year to have leading zeros if necessary
        day = String(day).padStart(2, '0');
        month = String(month).padStart(2, '0');

        // Create the formatted date string in "dd-mm-yyyy" format
        let formattedDate = `${day}-${month}-${year}`;

        dispatch(fetchAvailabilityData({
            id: data?.id,
            date: formattedDate
        }));
    };
    const closeDate = () => {
        setShowCalendar(false)
    }
    const currentDate = appoinmentDate ? new Date(appoinmentDate) : new Date();
    const options = { day: 'numeric', month: 'long', year: ('numeric') };
    const options2 = { weekday: 'long' };
    const options3 = { month: 'short', day: 'numeric', year: 'numeric' };
    const formattedDate = currentDate?.toLocaleDateString('en-US', options);
    const day1 = currentDate?.toLocaleDateString('en-US', options2);
    const nextDay = new Date(currentDate);
    const today = new Date();
    nextDay.setDate(currentDate.getDate() + 1);
    const formattedDate2 = nextDay?.toLocaleDateString('en-US', options);
    const day2 = nextDay?.toLocaleDateString('en-US', options2);
    const newdate = value;
    const formattedDate3 = newdate?.toLocaleDateString('en-US', options);
    const openPopup = (id) => {
        setdefaultindex(0)
        setShow(true)
        setProviderId(id)
        getAvailableSlot({
            id: data?.id,
            date: formattedDate
        })
    }
    const getAvailableSlot = async (data) => {

        const currentDate = new Date(data?.date);
        // Extract day, month, and year from the currentDate object
        let day = currentDate.getDate();
        let month = currentDate.getMonth() + 1; // Note: January is 0
        let year = currentDate.getFullYear();

        // Format day, month, and year to have leading zeros if necessary
        day = String(day).padStart(2, '0');
        month = String(month).padStart(2, '0');

        // Create the formatted date string in "dd-mm-yyyy" format
        let formattedDate = `${day}-${month}-${year}`;

        dispatch(fetchAvailabilityData({
            id: data?.id,
            date: formattedDate
        }));
        closeDate()
    }

    const handleSelectSlot = ({ slot,exp, available, provider, date }) => {

        var newdate = new Date(date);

        // Get the day, month, and year
        var day = newdate.getDate();
        var month = newdate.getMonth() + 1; // Months are zero-based, so we add 1
        var year = newdate.getFullYear();
        // Format the date to 'dd-mm-yyyy' format
        var formattedDate = (day < 10 ? '0' : '') + day + '-' + (month < 10 ? '0' : '') + month + '-' + year;

        if (available == 1 && exp==0) {
            setselectSlot(slot)
            dispatch(setselectedSlot({ id: slot, date: date }))
            navigate('/booking-detail/' + provider)
            dispatch(fetchAvailableSlotByid({ id: slot, date: formattedDate }))
        }
    }
    const handleDateChange = (selectedDate) => {
        const formattedcusDate = selectedDate?.toLocaleDateString('en-US', options);

        setcustomdate(formattedcusDate)
        setValue(selectedDate);
        getAvailableSlot({
            id: data?.id,
            date: selectedDate
        })
        setShowCalendar(false); // Close the calendar when a date is selected
    };
    const distance = data?.distance/1000 
    return (
        <>
            <div className="col-12 col-lg-6 mt-3">
                <div className="doctor-details">
                    <div className="row">
                        <div className="col-4">
                            <img src={data?.service_provider_photo_url} className="img-first" />
                        </div>
                        <div className="col-8">
                            <div className="doctor-name">
                                <h3>{data?.service_provider_name}</h3>
                                <span><img src={check} /></span>
                            </div>
                            <h5>{data?.specialty?.speciality_name}</h5>
                            <h5>{data?.provider_type ==3 ?data?.firm_name : data?.category?.category_name}</h5>
                            <div className="row" style={{ alignItems: 'center' }}>
                                <div className="col-5 booking-fees">Booking Fees</div>
                                <div className="col-7 fee-per-person">₹{data?.booking_charge} / person</div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ margin: '10px 0' }}>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-1" style={{ paddingRight: 0 }}>
                                    <img src={Vector} className="location" />
                                </div>
                                <div className="col-5 Booking-num">{data?.service_provider_address}</div>
                                {data?.distance &&
                                 <div className="col-6 Booking-num">{distance?.toFixed(2)} Km</div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: '15px' }}>
                        <div className="col-6">
                            <button className="profile-view" onClick={() => { navigate('/view-profile/' + data?.id) }}>View Profile</button>
                        </div>
                        <div className="col-6"><button className="book-now btn btn-primary"
                            data-toggle="modal" data-target={`#exampleModal${data?.id}`} onClick={(e) => openPopup(data?.id)}>book now</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* popup */}
            {show && (<div className="modal fade button-main" id={`exampleModal${data?.id}`} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <Tabs selectedIndex={defaultindex}>
                            <TabList>
                                <Tab onClick={() =>{ getAvailableSlot({
                                    id: data?.id,
                                    date: formattedDate
                                })
                                setdefaultindex(0)
                                }}>{appoinmentDate ? (new Date(appoinmentDate)?.toLocaleDateString('en-US', options3) == new Date()?.toLocaleDateString('en-US', options3) ? 'Today' : day1) : day1}<p>{formattedDate}</p></Tab>
                                <Tab onClick={() => {getAvailableSlot({
                                    id: data?.id,
                                    date: formattedDate2
                                })
                                setdefaultindex(1)
                                }}>{appoinmentDate ? (new Date(appoinmentDate)?.toLocaleDateString('en-US', options3) == new Date()?.toLocaleDateString('en-US', options3) ? 'Tomorrow' : day2) : day2}<p>{formattedDate2}</p></Tab>
                                <Tab onClick={() => handleButtonClick({
                                    id: data?.id,
                                    date: customdate
                                })}>More date<p>{formattedDate3 ? formattedDate3 : 'See calender'}</p>

                                </Tab>
                            </TabList>
                            <TabPanel>
                                {availableSlots ?
                                    <div className="row large-2">
                                        {availableSlots?.map((slot, index) => {
                                            const date = new Date(`2000-01-01T${slot.time_start}`);
                                            const formattedStart = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

                                            const date2 = new Date(`2000-01-01T${slot.time_end}`);
                                            const formattedEnd = date2.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });


                                            return (
                                                <div key={slot?.id} className="col-4">
                                                    {slot?.is_available == 1 ?
                                                        <div  data-dismiss={slot.is_expired == 1?"":"modal"} key={slot?.id} onClick={() => handleSelectSlot({ slot: slot?.id,exp:slot?.is_expired ,available: slot?.is_available, provider: data?.id, date: formattedDate })}>
                                                            <div className={slot.is_expired == 1 ? "availblae-active-checktime" :
                                                                slot.is_available == 0 ? "availblae-active-disactive" :
                                                                    slot.id === selectSlot ? "availblae" : "availblae-active"} >
                                                                {formattedStart} - {formattedEnd}<br />
                                                                {slot.remaining_count} Available
                                                            </div>
                                                        </div> :
                                                        <div key={slot?.id}>
                                                            <div className={slot.is_expired == 1 ? "availblae-active-checktime" :
                                                                slot.is_available == 0 ? "availblae-active-disactive" :
                                                                    slot.id === selectSlot ? "availblae" : "availblae-active"} >
                                                                {formattedStart} - {formattedEnd}<br />
                                                                {slot.remaining_count} Available
                                                            </div>
                                                        </div>}
                                                </div>

                                            )
                                        })}
                                    </div> :
                                    <div className="row large-2" >
                                        <div className='col-12' style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <p>{sloteMsg}</p>
                                        </div>
                                    </div>
                                }
                            </TabPanel>
                            <TabPanel>
                                {availableSlots ?
                                    <div className="row large-2">
                                        {availableSlots?.map((slot, index) => {
                                            const date = new Date(`2000-01-01T${slot.time_start}`);
                                            const formattedStart = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

                                            const date2 = new Date(`2000-01-01T${slot.time_end}`);
                                            const formattedEnd = date2.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });


                                            return (
                                                <div className="col-4" data-dismiss={slot.is_expired == 1?"":"modal"} key={index} onClick={() => handleSelectSlot({ slot: slot?.id,exp:slot?.is_expired ,available: slot?.is_available, provider: data?.id, date: formattedDate2 })}>
                                                    <div className={slot.is_expired == 1 ? "availblae-active-checktime" :
                                                        slot.is_available == 0 ? "availblae-active-disactive" :
                                                            slot.id === selectSlot ? "availblae" : "availblae-active"} >
                                                        {formattedStart} - {formattedEnd}<br />
                                                        {slot.remaining_count} Available
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div> :
                                    <div className="row large-2" >
                                        <div className='col-12' style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <p>{sloteMsg}</p>
                                        </div>
                                    </div>
                                }
                            </TabPanel>
                            <TabPanel >
                                {availableSlots ?
                                    <div className="row large-2">
                                        {availableSlots?.map((slot, index) => {
                                            const date = new Date(`2000-01-01T${slot.time_start}`);
                                            const formattedStart = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

                                            const date2 = new Date(`2000-01-01T${slot.time_end}`);
                                            const formattedEnd = date2.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });


                                            return (
                                                <div className="col-4" data-dismiss="modal" key={index} onClick={() => handleSelectSlot({ slot: slot?.id,exp:slot?.is_expired ,available: slot?.is_available, provider: data?.id, date: customdate })}>

                                                    <div className={slot.is_expired == 1 ? "availblae-active-checktime" :
                                                        slot.is_available == 0 ? "availblae-active-disactive" :
                                                            slot.id === selectSlot ? "availblae" : "availblae-active"} >
                                                        {formattedStart} - {formattedEnd}<br />
                                                        {slot.remaining_count} Available
                                                    </div>
                                                </div>

                                            )
                                        })}
                                    </div> :
                                    <div className="row large-2" >
                                        <div className='col-12' style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <p>{sloteMsg}</p>
                                        </div>
                                    </div>
                                }
                                {showCalendar && (
                                    <Calendar onChange={handleDateChange} value={value} minDate={today} />
                                )}
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>)}
        </>
    )
}

export default ListingCard
