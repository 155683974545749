import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AboutusData } from '../services/ApiServices';
import AppFooter from '../components/Common/AppFooter';
import AppHeader from '../components/Common/Header/AppHeader';

const AboutUs = () => {
  const [data, setData] = useState([])
  useEffect(() => {
    getaboutus()
    window.scrollTo(0, 0);
  }, []);

  const getaboutus = async () => {
    try {
      const response = await AboutusData();
      setData(response.Data)
    } catch (error) {

    }
  }

  return (
    <div className="inner_page_layout common_page">
      <AppHeader />
      <div className="layout-pg">
        <div className="content_pg">
          <div className="wrapper">
            <div className="abt_flex">
              <div className="co_abt">
                <h2>About Us</h2>
                <p>{data?.description}</p>
              </div>
              <img src={data?.image_url} alt="About Us" />
            </div>
          </div>

          <div className="counter">
            <div className="wrapper">
              <div className="flex_counter">
                <div className="countre_box">
                  <span>{data?.services}+</span>
                  <p>Services</p>
                </div>
                <div className="countre_box">
                  <span>{data?.service_providers}+</span>
                  <p>Service Providers</p>
                </div>
                <div className="countre_box">
                  <span>{data?.customers}+</span>
                  <p>Happy Customers</p>
                </div>
              </div>
            </div>
          </div>
          <div className="aims">
            <div className="wrapper">
              <div className="flex_counter">
                <div className="aim_box">
                  <h3>Vision</h3>
                  <p>{data?.vision}</p>
                </div>
                <div className="aim_box">
                  <h3>Mission</h3>
                  <p>{data?.mission}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppFooter />
    </div>
  );
};

export default AboutUs;
