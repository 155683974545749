import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchSearchResult } from '../../store/SearchServices';
import { setSelectedSpeciality } from '../../store/FilterSliceList';
import { Tooltip } from "@mui/material";
import "react-tooltip/dist/react-tooltip.css";

const SingleSpeciality = ({ item }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchFilters = {
    city: '',
    location: '',
    category: item.category_id,
    subcategory: '',
    specialty: item.id,
    date: '',
    name: '',
  };

  const book = () => {
    // alert("test");
    // Assuming you have a Redux store and an action named "searchProviders"
    dispatch({ type: 'SEARCH_PROVIDERS', searchFilters });
  };
  const search = (id, name) => {
    const dateObject = new Date();
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const formattedDate = dateObject.toLocaleDateString('en-IN', options).replace(/\//g, '-');
    // dispatch(setProviderType(1))
    dispatch(setSelectedSpeciality({ label: name, value: id }))
    if (id) {

      navigate('/services')
    }
  };

  return (
    <div className="col-12 col-lg-2 col-md-6 col-sm-12">
      <div className="single_box item">
        <img src={item.speciality_icon_url} alt={item.speciality_name} />
        <Tooltip placement="top-start" title={item.speciality_name}>
                      
                      <h3 style={{ width: "170px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{item.speciality_name}</h3>
                                                    </Tooltip>
 
        <p onClick={() => search(item.id, item.speciality_name)} className='spec-book'>Book Now</p>
      </div>
    </div>
  );
};

export default SingleSpeciality;
