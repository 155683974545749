import React, { useState, useRef } from 'react';
import logo from './asstes/images/logo.a927d1cd.png';
import './asstes/Css/Style.css';
import './asstes/Css/Responsive.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { setUserInfo } from "../../../store/userSlice";
import TopHeader from './TopHeader';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';


function AppHeader() {
    const dispatch = useDispatch();
    const { userInfo ,username} = useSelector((state) => state.userDetails);
    const [showAlert, setShowAlert] = useState(false);
    const [dropdownV, setDropdownV] = useState('');
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const currentPath = location.pathname;
  

    const alertLog = () => {
        setShowAlert(true);
    };
    const dropdownClick = () => {
        setDropdownVisible(!isDropdownVisible);
        if (dropdownV == 'activeShow') {
            setDropdownV('')
        } else {
            setDropdownV('activeShow')
        }

    };
    const closeAlert = () => {
        setShowAlert(false);
    };
    const logout = () => {
        dispatch(setUserInfo())
        closeAlert()
        Swal.fire({
            icon: 'success',
            text: 'Logout success',
            showConfirmButton: false,
            timer: 2000
          })
          document.body.style.overflow = "";
          navigate('/')
    };

    return (
        <div>
            <div className="header-main d-none d-lg-block">
                <TopHeader />
                <div className="menu">
                    <div className="wrapper">
                        <div className="menu-bar">
                            <div className="header-mainMenu2"><a href="/" className="router-link-active"><img alt="Vue logo"
                                src={logo} /></a></div>
                            <div className="mob-men">
                                <div className="header-mainMenu">
                                    <ul className='main_menu_list_ul'>
                                        <li><Link to={"/"} className={currentPath==='/' || currentPath==='/Home'?'router-link-exact-active router-link-active':'router-link-active'}>Home</Link></li>
                                        <li><Link to={"/services"} aria-current="page"
                                            className={currentPath==='/services'?'router-link-exact-active router-link-active':'router-link-active'}>Services</Link></li>
                                        <li><Link to={"/about-us"} className={currentPath==='/about-us'?'router-link-exact-active router-link-active':'router-link-active'}>About Us</Link></li>
                                        <li><Link to={"/news-events"} className={currentPath==='/news-events'?'router-link-exact-active router-link-active':'router-link-active'}>News &amp; Events</Link></li>
                                        <li><Link to={"/contact-us"} className={currentPath==='/contact-us'?'router-link-exact-active router-link-active':'router-link-active'}>Contact Us</Link></li>
                                    </ul>
                                    {userInfo?.access_token ? (
                                        <div className="drop-down-main d-none d-lg-block">
                                            <div className="dropdown">
                                                <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{textTransform:"capitalize"}}>
                                                    <i className="fa-solid fa-user" style={{ padding: '5px 5px 5px 0px', fontSize: '10px' }}></i>
                                                    {username? username : ""}
                                                </button>
                                                <ul className="dropdown-menu drp-dwn">
                                                 <li className='myli' onClick={()=>navigate('/my-account')} style={{cursor:"pointer"}}>My Account</li>
                                                  
                                                    <li className='myli' onClick={alertLog} style={{cursor:"pointer"}}>Logout</li>
                                                </ul>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="signin-bt">
                                            <Link to="/Sign-in">Login / Signup</Link>
                                        </div>
                                    )}
                                </div>
                                <div className="menu_button">
                                    <div><span></span><span></span><span></span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* mobile */}
            <div className="header-main d-block d-lg-none">
                <TopHeader />
                <div className="menu">
                    <div className="wrapper">
                        <Navbar expand={false} className="bg-body-primary d-block d-lg-none">
                            <Container fluid>
                                <div className="menu-bar">
                                    <div className="header-mainMenu2"><a href="/" className="router-link-active"><img alt="Vue logo"
                                        src={logo} /></a></div>
                                </div>
                                {userInfo?.access_token ?
                                    <div className="dropdownCls hideOnWeb">
                                        <a onClick={dropdownClick} className="drp-clc">
                                            <FontAwesomeIcon icon={faUser} style={{ color: 'black' }} /> <FontAwesomeIcon icon={faChevronDown} style={{ color: 'black' }} />
                                        </a>
                                        <div className={`drp-dwn ${dropdownV}`} ref={dropdownRef}>
                                        <li className='myli' onClick={()=>navigate('/my-account')} style={{cursor:"pointer"}}>My Account</li>
                                           
                                            <li className='myli' onClick={alertLog} style={{cursor:"pointer"}}>Logout</li>
                                        </div>
                                    </div> : ''}
                                <Navbar.Toggle aria-controls="offcanvasNavbar" />
                                <Navbar.Offcanvas
                                    id="offcanvasNavbar"
                                    aria-labelledby="offcanvasNavbarLabel"
                                    placement="end"
                                >
                                    <Offcanvas.Header closeButton>
                                        <Offcanvas.Title id="offcanvasNavbarLabel"></Offcanvas.Title>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body>
                                        <Nav className="justify-content-end flex-grow-1 pe-3" style={{ display: "block" }}>
                                            <div className="header-mainMenu">
                                                <ul>
                                        <li><Link to={"/"} className={currentPath==='/' || currentPath==='/Home'?'router-link-exact-active router-link-active':'router-link-active'}>Home</Link></li>
                                        <li><Link to={"/services"} aria-current="page"
                                            className={currentPath==='/services'?'router-link-exact-active router-link-active':'router-link-active'}>Services</Link></li>
                                        <li><Link to={"/about-us"} className={currentPath==='/about-us'?'router-link-exact-active router-link-active':'router-link-active'}>About Us</Link></li>
                                        <li><Link to={"/news-events"} className={currentPath==='/news-events'?'router-link-exact-active router-link-active':'router-link-active'}>News &amp; Events</Link></li>
                                        <li><Link to={"/contact-us"} className={currentPath==='/contact-us'?'router-link-exact-active router-link-active':'router-link-active'}>Contact Us</Link></li>
                                                </ul>
                                                {userInfo?.access_token ? '' : <div className="signin-bt"><Link to={'/sign-in'} className="">Login / Signup</Link></div>}
                                            </div>
                                        </Nav>
                                    </Offcanvas.Body>
                                </Navbar.Offcanvas>
                            </Container>
                        </Navbar>
                    </div>
                </div>
            </div>
            {showAlert && (
                <div className="updateSuccess popup-login">
                    <div>
                        <p>Are you sure you want to logout?</p>
                        <div className="yN">
                            <button onClick={closeAlert}>Cancel</button>
                            <button onClick={logout}>Confirm</button>
                        </div>
                    </div>
                </div>
            )}
            {/* mobile end */}
        </div>
    );
}

export default AppHeader;