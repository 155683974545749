import { combineReducers, configureStore,getDefaultMiddleware } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from "redux-persist/lib/storage";
import HomeReducer from "./HomeSlice";
import NewsReducer from "./NewsSlice";
import TestimonialReducer from "./TestimonialsSlice";
import UserReducer from "./userSlice";
import ContactReducer from "./ContactusSlice";
import FilterListreducer from "./FilterSliceList";
import SearchReducer from "./SearchServices";
import FirmReducer from "./SearchFirm";
import SlotReducer from "./AvailabilitySearch";
import ViewdetailReducer from './ServiceproviderDetail';
import FavlistReducer from './FavoriteList';
import firmmemberReducer from './FirmMemberlist';
import accountReducer from './Accountlist';
import slotAvailReducer from './AvailableSlot';
import familyListReducer from './FamilymemberList';
import kyclistReducer from './Kyclist'

const persistConfig = {
  key: "root",
  storage,
  whiteList: [
    "Homefilter", 
    "Newsfilter",
    "Testimonialfilter",
    "userDetails",
    "ContactInfo",
    "filterdata_list",
    "search",
    "firm_list",
    "availableSlots",
    "viewDetail",
    "favList",
    "firmmember",
    "accountlist",
    "availSlot",
    "familyList",
    "kyclist"
  ],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    Homefilter: HomeReducer,
    Newsfilter: NewsReducer,
    Testimonialfilter:TestimonialReducer,
    userDetails:UserReducer,
    ContactInfo:ContactReducer,
    filterdata_list:FilterListreducer,
    search:SearchReducer,
    firm_list:FirmReducer,
    availableSlots:SlotReducer,
    viewDetail:ViewdetailReducer,
    favList: FavlistReducer,
    firmmember: firmmemberReducer,
    accountlist: accountReducer,
    availSlot:slotAvailReducer,
    familyList:familyListReducer,
    kyclist:kyclistReducer,
  })
);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

const persistor = persistStore(store);

export { store, persistor };
