import React, { useEffect, useState } from 'react'
import './asset/Notification.css'
import { Notificationlist } from '../../services/ApiServices'
import AccountSideBar from './AccountSideBar';
import Loaders from '../Loader/Loaders';
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';

function NotificationList() {

    const [notify, setnotify] = useState([])
    const [loading, setloading] = useState(true)
    const [pagination, setpagination] = useState([])
    const [page, setpage] = useState(1)

    useEffect(() => {
        getnotification({ page })
    }, [])
    const getnotification = async ({ page }) => {

        try {
            const response = await Notificationlist({ page });
            setloading(false)
            setnotify(response.Data.data)
            setpagination(response.Data)
        }
        catch (err) {
            setloading(false)

        }
    }
    const totalPages = Math.ceil(pagination?.total / pagination?.per_page);

    const handlePageChange = (newPage) => {

        setpage(newPage)
        getnotification({ page: newPage })

    };
    return (
        <form>
            <Loaders loading={loading} />
            <div className="row">
                <AccountSideBar page="notification" />
                <div className="col-12 col-lg-9">
                    <div className="my-accountt">
                        <h2> Notifications </h2>
                        {notify?.length > 0 ?
                            <>{notify?.map((obj,index) => {
                                return (
                                    <div className="row" key={index}>
                                        <div className="favorates-main" style={{ padding: "10px 20px" }}>
                                            <div className="row" style={{ alignItems: "center" }}>
                                                <div className="col-2 col-lg-1">
                                                    <div className="notification-main">
                                                        <i class="fa-solid fa-bell"></i>
                                                    </div>
                                                </div>
                                                <div className="col-10">
                                                    <h5 style={{textTransform:"capitalize"}}><b>{obj?.title}</b></h5>
                                                    <p>{obj?.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                                <div style={{ marginTop: "50px" }}>
                                    <ResponsivePagination
                                        current={page}
                                        total={totalPages}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                            </> :
                            <div style={{ textAlign: "center" }}>No notifications !</div>
                        }
                    </div>
                </div>
            </div>
        </form>
    )
}

export default NotificationList
