import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import AppFooter from '../components/Common/AppFooter';
import DetailNews from '../components/Home/DetailNews';
import AppHeader from '../components/Common/Header/AppHeader';

const NewsnEvents = () => {

  const {top_news} = useSelector((state) => state.Newsfilter);
  useEffect(() => {
    window.scrollTo(0, 0); 
}, []);
  return (
    <div className="list_page_layout">
      <AppHeader />
      <div className="wrapper">
        <h2 className="space-h2">News & Events</h2>
        {top_news?.length === 0 ? (
          <div className="center-loader">
            <span className="loader"></span>
          </div>
        ) : (
          <div className="newsList">
            {top_news?.map((news, i) => (
              <DetailNews key={i} news={news} />
            ))}
          </div>
        )}
      </div>
      <AppFooter />
    </div>
  );
};

export default NewsnEvents;
