import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const AdsHome = () => {
    const { advertisements } = useSelector((state) => state.Homefilter);

  return (
    <div>
      <OwlCarousel
        responsive={{
          0: { items: 1, nav: false, dots: false, autoplay: true },
          600: { items: 2, nav: false, dots: false, margin: 20, autoplay: true },
        }}
      >
        {advertisements?.map((item, index) => (
          <a key={index}  className="show-hide-img image">
            <img alt="React logo" src={item.ads_image} style={item.style} />
          </a>
        ))}
      </OwlCarousel>
    </div>
  );
};

export default AdsHome;
