import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFirmMemberslist } from "../services/ApiServices";


export const fetchfirmMemberList = createAsyncThunk(
  "filters/fetchfirmMemberList",
  async ({id,specId,appointment_date,page}) => {
    
    const data = await getFirmMemberslist({id,specId,appointment_date,page});
        return data
      }
    );
const FirmMemberSlice = createSlice({
  name: "firmmember",
  initialState: {
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchfirmMemberList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchfirmMemberList.fulfilled, (state, action) => {
        state.listfirmmember=action.payload?.Data?.data;
        state.totalRecords = action.payload?.Data.total;
        state.per_page = action.payload?.Data.per_page;
        state.sloteMsg=action.payload?.Message;
        state.loading = false;
      })
      .addCase(fetchfirmMemberList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { 
 } = FirmMemberSlice.actions;

export default FirmMemberSlice.reducer;