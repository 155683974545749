import React, { useEffect, useState } from 'react'
import './asset/Myfamily.css'
import AccountSideBar from './AccountSideBar'
import { useDispatch, useSelector } from 'react-redux';
import { deletefamilymember, familymembarList, getfamilyotp } from '../../services/ApiServices';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { fetchFamilylist } from '../../store/FamilymemberList';
import Loaders from '../Loader/Loaders';
import { setEditMember } from '../../store/userSlice';
import { Tooltip } from "@mui/material";
import "react-tooltip/dist/react-tooltip.css";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';

const MyfamilyForm = () => {
    const { userInfo } = useSelector((state) => state.userDetails);
    const [memberid, setmemberid] = useState()
    const [confirmdelete, setconfirmdelete] = useState(false)
    const [loadingotp, setloadingotp] = useState(false)
    const { myFamily, loading, pagination } = useSelector((state) => state.familyList);
    const [page, setpage] = useState(1)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchFamilylist({ page }))
    }, [])


    const handledelete = (id) => {
        setconfirmdelete(true)
        setmemberid(id)
    }

    const handleconfirmdelete = async () => {
        try {
            const response = await deletefamilymember({ id: memberid, userInfo: userInfo });
            if (response.Status == 1) {
                toast.success(response.Message)
                dispatch(fetchFamilylist({ page }))
            }
            setconfirmdelete(false)
        } catch (error) {

        }
    }
    const handlegetotp = async (num) => {
        setloadingotp(true)
        try {
            const response = await getfamilyotp({ num: num, userInfo: userInfo });

            setloadingotp(false)
            if (response.Status == 1) {
                toast.success(response.Message)
            }
            else {
                toast.warn(response.Message)
            }
            navigate('/family-otp-verify', {
                state: {
                    data: response.Data,

                }
            })


        } catch (error) {
            setloadingotp(false)

        }
    }
    const handleEdit = (data) => {
        navigate('/edit-member')
        dispatch(setEditMember(data))
    }
    const totalPages = Math.ceil(pagination?.total / pagination?.per_page);

    const handlePageChange = (newPage) => {

        setpage(newPage)
        dispatch(fetchFamilylist({ page: newPage }))

    };
    return (
        <div>
            <Loaders loading={loading || loadingotp} />
            <div className="row">
                <AccountSideBar page={"myfamily"} />
                <div className="col-12 col-lg-9">
                    <div className="my-account" style={{ background: "transparent", boxShadow: "unset", paddingTop: "0" }}>
                        <div className="row" style={{justifyContent:"space-between"}}>

                        <h2 className="col-12 col-lg-3">My Family </h2>
                        <button className='add_family  col-lg-3 col-md-6 col-sm-12' onClick={()=>navigate('/add-member')}>Add Family Member</button>
                        </div>
                        <div className="row">
                            {myFamily?.length > 0 ?
                                <>{myFamily?.map((obj) => {
                                    return (
                                        <div className="col-12 col-lg-6" key={obj?.id}>
                                            <div className="myfamily-box">
                                                <div className="row">
                                                    <div className="col-8"><h4 class="main-h4" style={{ textTransform: "capitalize" }}>{obj.name}</h4></div>
                                                    <div className="col-2" style={{ cursor: "pointer" }} onClick={() => handleEdit(obj)}><i class="fa-solid fa-pen" style={{ color: "#0085ff" }}></i><h4 className='edit-h4' style={{ color: "#0085ff", fontSize: "14px", fontWeight: "normal" }}>Edit</h4></div>
                                                    <div className="col-2" style={{ cursor: "pointer", paddingRight: "0" }} onClick={() => handledelete(obj.id)}><i class="fa-solid fa-trash" style={{ color: "red" }}></i><h4 className='edit-h4' style={{ color: "red", fontSize: "14px", fontWeight: "normal" }}>Delete</h4></div>
                                                </div>
                                                <div className="row description-main">
                                                    <div className="col-5">DOB  </div>
                                                    <div className="col-2">:</div>
                                                    <div className="col-5">{obj.dob}</div>
                                                </div>
                                                <div className="row description-main">
                                                    <div className="col-5">Gender  </div>
                                                    <div className="col-2">:</div>
                                                    <div className="col-5" style={{ textTransform: "capitalize" }}>{obj.gender?.name}</div>
                                                </div>
                                                <div className="row description-main">
                                                    <div className="col-5">Email  </div>
                                                    <div className="col-2">:</div>
                                                    <Tooltip placement="top-start" title={obj.email}>
                                                        <div className="col-5" style={{ width: "160px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}> {obj.email}</div>
                                                    </Tooltip>
                                                </div>
                                                <div className="row description-main">
                                                    <div className="col-5">Mobile  </div>
                                                    <div className="col-2">:</div>
                                                    <div className="col-5">{obj.mobile}</div>
                                                </div>
                                                <div className="row description-main">
                                                    <div className="col-5">  </div>
                                                    <div className="col-7">
                                                        {obj.is_otp_verified ?
                                                            <b class="verified-fam"

                                                            ><i class="fa-solid fa-check"></i> &nbsp;Mobile verified</b>
                                                            :
                                                            <b class="not-verified-fam" onClick={() => { handlegetotp(obj.mobile) }}><i class="fa-solid fa-xmark"></i> &nbsp;Verify Mobile Number</b>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                                    <div style={{ marginTop: "50px" }}>
                                        <ResponsivePagination
                                            current={page}
                                            total={totalPages}
                                            onPageChange={handlePageChange}
                                        />
                                    </div>
                                </> : <div style={{ textAlign: "center" }}>No family  member !</div>}

                        </div>
                    </div>
                </div>
            </div>
            {confirmdelete && (
                <div className="updateSuccess popup-login">
                    <div>
                        <p>Are you sure you want to delete?</p>
                        <div className="yN">
                            <button onClick={() => setconfirmdelete(false)}>Cancel</button>
                            <button onClick={handleconfirmdelete}>Confirm</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default MyfamilyForm
