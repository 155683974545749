import React, { useEffect, useState } from 'react'
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'; 
import Loaders from '../Loader/Loaders';
import AccountSideBar from './AccountSideBar';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { addFamilyMember, getBloodgrouplist, getfamilyotp, relationList } from '../../services/ApiServices';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useNavigate } from 'react-router';
import Typography from '@mui/material/Typography';

const MyfamilyAdd = () => {
    const mailregex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const schema = yup.object().shape({
        name: yup.string().required("Name is required"),
        email: yup.string().required("Email is required").matches(mailregex,"Invalid email"),
        mobile: yup.string().required("Mobile number required"),
        gender: yup.string().required("Gender is required"),
        relation: yup.string().required("Relation is required"),
        dob: yup.string().required("Date of birth is required"),
        blood_group: yup.string().required("Blood group is required"),
        address: yup.string().required("Address is required"),
    })
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        clearErrors,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    useEffect(() => {
        listRelation()
        listBloodgroup()
        // formatDate()
        setDate2()
    }, [])

    const [loading, setloading] = useState(false)
    const [relation, setRelation] = useState([]);
    const [bloodGrp, setBloodGrp] = useState([]);
    const [date2, setDate2] = useState();
    const navigate = useNavigate()
    
    const listRelation = async () => {
        try {
            const response = await relationList();
            setRelation(response.Data)
        } catch (error) {
            
        }
    }
    const listBloodgroup = async () => {
        try {
            const response = await getBloodgrouplist();
            setBloodGrp(response.Data)
        } catch (error) {
            
        }
    }
    const onSubmit = async (data) => {
        setloading(true)
        try {
            const response = await addFamilyMember({data});
            toast.success(response.Message)
            if(response.Status == 1){
               try {
                const response1 = await getfamilyotp({num: response.Data.mobile });
    
                setloading(false)
                if (response1.Status == 1) {
                    toast.success(response1.Message)
                }
                else {
                    toast.warn(response1.Message)
                }
                navigate('/family-otp-verify', {
                    state: {
                        data: response1.Data,
                    }
                })
            } catch (error) {
                setloading(false)
                
            } 
            }
            else{
                setError('mobile', { type: 'custom', message: response.Message.mobile });
                setloading(false)
            }                  
            
        } catch (error) {
            
            setloading(false)
            // Add logic for handling login failure (e.g., show error message)
        }
    }
    const handleDateChange2 = (selectedDate) => {
        setDate2(selectedDate);
        
        let dateconvert = moment(selectedDate).format('YYYY-MM-DD')
        setValue("dob", dateconvert)
    };
    const RequiredLabel = ({ labelText }) => (
        <div>
          <Typography component="span">{labelText}</Typography>
          <Typography component="span" style={{ color: 'red', fontSize: "20px" }}>
            *
          </Typography>
        </div>
      );
  return (
    <div>
    <Loaders loading={loading} />
    <div className="row">
        <AccountSideBar page={"myfamily"} />
        <div className="col-12 col-lg-9">
            <div className="my-account" style={{ background: "transparent", boxShadow: "unset", paddingTop: "0" }}>
               
            <form onSubmit={handleSubmit(onSubmit)} style={{ paddingRight: "0" }}>
                        <div className="create-account-form">
                            <div className="create-form-inner">
                                <div className="row">
                                    <div className="col-6" style={{ paddingRight: "0" }}>
                                        <TextField id="standard-basic" label={<RequiredLabel labelText="Full Name" />} variant="standard" name='name' {...register("name")} />
                                        <div className="errorDetails">{errors.name ? errors.name.message : ''}</div>
                                    </div>
                                    <div className="col-6 margin-change">
                                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                            <InputLabel id="demo-simple-select-standard-label"><RequiredLabel labelText="Gender" /></InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard" s
                                                
                                                name="gender"
                                                {...register("gender")}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value='1'>Male</MenuItem>
                                                <MenuItem value='2'>Female</MenuItem>
                                                <MenuItem value='3'>Other</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <div className="errorDetails">{errors.gender ? errors.gender.message : ''}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="create-form-inner">
                                <div className="row">
                                    <div className="col-6" style={{ paddingRight: "0" }}>
                                        <TextField id="standard-basic" label={<RequiredLabel labelText="Mobile Number" />} variant="standard" name='mobile' {...register("mobile")} 
                                        inputProps={{maxLength:10}}/>
                                        <div className="errorDetails">{errors.mobile ? errors.mobile.message : ''}
                                       </div>
                                    </div>
                                    <div className="col-6 margin-change">
                                        <TextField id="standard-basic" label={<RequiredLabel labelText="Email Id" />} variant="standard" name='email' {...register("email")} />
                                        <div className="errorDetails">{errors.email ? errors.email.message : ''}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="create-form-inner">
                                <div className="row">
                                    <div className="col-6 margin-change">
                                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                            <InputLabel id="demo-simple-select-standard-label"><RequiredLabel labelText="Relation" /></InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                name="relation"
                                                {...register("relation")}
                                                label="Relation"
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {relation?.map((obj) => (<MenuItem key={obj?.id} value={obj?.id}>{obj.name}</MenuItem>))}
                                            </Select>
                                        </FormControl>
                                        <div className="errorDetails">{errors.relation ? errors.relation.message : ''}</div>
                                    </div>
                                    <div className="col-6" style={{ paddingRight: "0" }}>
                                        <div className="field-parent datepicker">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DatePicker']}>
                                                    <div className="date-picker-container">
                                                        <DatePicker
                                                            label={<RequiredLabel labelText="Dob" />}
                                                            disableFuture
                                                            value={date2}
                                                            onChange={handleDateChange2}
                                                        />
                                                        <div className="errorDetails">{errors.dob ? errors.dob.message : ''}</div>
                                                    </div>
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="create-form-inner">
                                <div className="row">
                                    <div className="col-6 margin-change">
                                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                            <InputLabel id="demo-simple-select-standard-label"><RequiredLabel labelText="Blood Group" /></InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard" s
                                                label="Blood group"
                                                name="blood_group"
                                                {...register("blood_group")}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {bloodGrp?.map((obj) => (<MenuItem key={obj?.id} value={obj?.id}>{obj.blood_group_name}</MenuItem>))}
                                            </Select>
                                        </FormControl>
                                        <div className="errorDetails">{errors.blood_group ? errors.blood_group.message : ''}</div>
                                    </div>
                                    <div className="col-6" style={{ paddingRight: "0" }}>
                                        <TextField id="standard-basic" label={<RequiredLabel labelText="Address" />} variant="standard" name='address' {...register("address")} />
                                        <div className="errorDetails">{errors.address ? errors.address.message : ''}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="addmemeber">Add Member</button>
                    </form>
            </div>
        </div>
    </div>
  
</div>
  )
}

export default MyfamilyAdd