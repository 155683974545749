import React from 'react'
import AppHeader from '../components/Common/Header/AppHeader'
import AppFooter from '../components/Common/AppFooter'
import FirmDetail from '../components/Detail/FirmDetail'

const ViewFirmDetail = () => {
    return (
        <div className="listing-service-main">
            <AppHeader />
            <div className="mainP HomePage">
                <div className="list_page_layout">
                    <div className="listing-main">

                        <FirmDetail />

                    </div>

                </div>

            </div>
            <AppFooter />
        </div>
    )
}

export default ViewFirmDetail
