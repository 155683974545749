import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { availableSlotByid } from "../services/ApiServices"


export const fetchAvailableSlotByid = createAsyncThunk(
  "filters/fetchAvailableSlotByid",
  async (params) => {
    const data = await availableSlotByid(params);
        return data.Data;
      }
    );
const AvailableSlotViewbyid = createSlice({
  name: "AvailableSlot",
  initialState: {
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailableSlotByid.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAvailableSlotByid.fulfilled, (state, action) => {
        state.AvailSlot=action.payload;
        state.loading = false;
      })
      .addCase(fetchAvailableSlotByid.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { 
 } = AvailableSlotViewbyid.actions;

export default AvailableSlotViewbyid.reducer;