import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getBookingHistoryList } from '../../services/ApiServices'
import { useNavigate } from 'react-router-dom'
import AccountSideBar from './AccountSideBar';
import './asset/History.css';
import Loaders from '../Loader/Loaders';
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import { setAppoinmentDate } from '../../store/FilterSliceList';

function BookHistoryList() {


    const [bookinglist, setbookinglist] = useState([])
    const [loading, setloading] = useState(true)
    const [pagination, setpagination] = useState([])
    const [page, setpage] = useState(1)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {

        getbookings({ page })
    }, [])

    const getbookings = async ({page}) => {
        try {
            const response = await getBookingHistoryList({ page });
            setbookinglist(response.Data.data)
            setpagination(response.Data)
            setloading(false)
        } catch (error) {
            setloading(false)

        }
    }
    const totalPages = Math.ceil(pagination?.total / pagination?.per_page);

    const handlePageChange = (newPage) => {

        setpage(newPage)
        getbookings({ page: newPage })

    };
    return (
        <div className="row">
            <Loaders loading={loading} />
            <AccountSideBar page="history" />
            <div className="col-12 col-lg-9">
                <div className="my-account" style={{ background: "transparent", boxShadow: "unset", paddingTop: "0" }}>
                    <h2>History</h2>
                    <div className="row">
                        {bookinglist?.length > 0 ?
                            <>{bookinglist?.map((obj) => {
                                const date = new Date(`2000-01-01T${obj.booking_time_from}`);
                                const formattedStart = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

                                const date2 = new Date(`2000-01-01T${obj.booking_time_to}`);
                                const formattedEnd = date2.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

                                const options = { day: '2-digit', month: 'short', year: 'numeric' };
                                const bookdate = new Date(obj.booking_date)
                                const formattedDate = bookdate.toLocaleDateString('en-US', options);
                                return (
                                    <div className="col-12 col-lg-6" key={obj?.service_provider_id}>
                                        <div className="history-inner">
                                            <div className="row" style={{ alignItems: "center" }}>
                                                <div className="col-12 col-md-4">
                                                    <div className="individual-img">
                                                        <img src={obj?.service_provider?.service_provider_photo_url}></img>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4">
                                                    <div class="history_info_b" style={{ maxWidth: "100%" }}>
                                                        <div class="tp"><h2>{obj?.service_provider?.provider_type == 1 ? obj?.service_provider?.service_provider_name : obj?.firm_name} </h2>
                                                            <span>{obj?.service_provider?.category?.category_name}</span>
                                                        </div>
                                                        <div>
                                                            <h2 class="st_c">{obj?.status?.book_status}</h2>
                                                            <span class="st_ts">{formattedDate}, {formattedStart}-{formattedEnd}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4">

                                                    <button class="view_profile" style={{ width: "unset" }} onClick={() => {navigate('/view-profile/' + obj?.service_provider_id)
                                                dispatch(setAppoinmentDate(new Date))}}>Book Again</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                                <div style={{ marginTop: "50px" }}>
                                    <ResponsivePagination
                                        current={page}
                                        total={totalPages}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                            </>
                            :
                            <div style={{ textAlign: "center" }}>No Bookings  yet!</div>
                        }



                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookHistoryList
