import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import AppFooter from '../components/Common/AppFooter';
import {getNewsByid} from '../services/ApiServices';
import AppHeader from '../components/Common/Header/AppHeader';

const NewsDetailpage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [data,setData] = useState([])
  useEffect(() => {
    getnewsdetail()
  }, []);
  const getnewsdetail = async ()  =>{
    try {
        const response = await getNewsByid(location.state.id);
        setData(response.Data)
    } catch (error) {
        
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0); 
}, []);
  return (
    <div className="inner_page_layout common_page">
      <AppHeader />
      <div className="d-sec">
        <div className="wrapper">
          <img src={data.image_url} alt="News" className="newsIm" />
          <div className="news_d single-news">
            <h3>{data.title}</h3>
            <div className="auth-d">
              <span>{data.date}</span>
            </div>
            <div className="co-news single-det-news">{data.description}</div>
          </div>
        </div>
      </div>
      <AppFooter />
    </div>
  );
};

export default NewsDetailpage;
