import ForgotPassword from "../components/Auth/ForgotPassword";
import ForgotReset from "../components/Auth/ForgotReset";
import OtpVerification from "../components/Auth/OtpVerification";
import ResendVerifyOtp from "../components/Auth/ResendVerifyOtp";
import SignIn from "../components/Auth/Signin";
import Signup from "../components/Auth/Signup";

export const RouterData = [
    { path: `sign-in`, element: <SignIn /> },
    { path: `sign-up`, element: <Signup /> },
    { path: `verify-otp`, element: <OtpVerification /> },
    { path: `forgot-password`, element: <ForgotPassword /> },
    { path: `verify-reset-otp`, element: <ResendVerifyOtp /> },
    { path: `forgot-reset`, element: <ForgotReset /> },
];