import React, { useEffect, useState } from 'react'
import AccountSideBar from './AccountSideBar'
import './asset/Mybookings.css'
import './asset/Bookingsresponsive.css'
import { useDispatch, useSelector } from 'react-redux'
import { getbookinglist, rescheduleBooking } from '../../services/ApiServices'
import { useNavigate } from 'react-router-dom'
import Loaders from '../Loader/Loaders'
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { fetchAvailabilityData } from '../../store/AvailabilitySearch'
import { setselectedSlot } from '../../store/FilterSliceList'
import { fetchAvailableSlotByid } from '../../store/AvailableSlot'
import { toast } from 'react-toastify'

const BookingList = () => {
    const dispatch = useDispatch()
    const [bookinglist, setbookinglist] = useState([])
    const [bookingId, setbookingId] = useState(null)
    const [providerId, setproviderId] = useState(null)
    const [pagination, setpagination] = useState([])
    const [loading, setloading] = useState(true)
    const [page, setpage] = useState(1)
    const [selectSlot, setselectSlot] = useState();
    const [bookeddate, setbookeddate] = useState()
    const [customdate, setcustomdate] = useState()
    const [date1, setDate1] = useState();
    const [showCalendar, setShowCalendar] = useState();
    const options = { day: 'numeric', month: 'short', year: ('numeric') };
    const options2 = { weekday: 'long' };
    const options3 = { month: 'short', day: 'numeric', year: 'numeric' };

    const today = new Date();

    const currentDate = new Date(bookeddate);
    const formattedDate1 = currentDate?.toLocaleDateString('en-US', options);
    const nextDay = new Date(bookeddate);
    nextDay.setDate(currentDate.getDate() + 1);
    const formattedDate2 = nextDay?.toLocaleDateString('en-US', options);
    const formattedDate3 = date1?.toLocaleDateString('en-US', options);
    const day1 = currentDate?.toLocaleDateString('en-US', options2);
    const day2 = nextDay?.toLocaleDateString('en-US', options2);
    const { availableSlots, sloteMsg } = useSelector((state) => state.availableSlots);
    const [selectedDate, setselectedDate] = useState();
    const [selectedDate1, setselectedDate1] = useState();
    const navigate = useNavigate()
    useEffect(() => {

        getbookings({ page })
    }, [])
    const handleButtonClick = () => {
        setShowCalendar(!showCalendar);
    };
    const getbookings = async ({ page }) => {
        try {
            const response = await getbookinglist({ page });
            setbookinglist(response.Data.data)
            setpagination(response.Data)
            setloading(false)
        } catch (error) {
            setloading(false)

        }
    }
    const totalPages = Math.ceil(pagination?.total / pagination?.per_page);
    const handlePageChange = (newPage) => {
        setpage(newPage)
        getbookings({ page: newPage })

    };
    const handlereschedule = (book, prov, date) => {
        setbookingId(book)
        setproviderId(prov)
        setbookeddate(date)
        getAvailableSlot({
            date: date,
            id: prov
        })
    }
    const getAvailableSlot = async (data) => {
        const currentDate = new Date(data?.date);
        // Extract day, month, and year from the currentDate object
        let day = currentDate.getDate();
        let month = currentDate.getMonth() + 1;
        let year = currentDate.getFullYear();

        // Format day, month, and year to have leading zeros if necessary
        day = String(day).padStart(2, '0');
        month = String(month).padStart(2, '0');
        setselectedDate1(formattedDate1)
        // Create the formatted date string in "dd-mm-yyyy" format
        let formattedDate = `${day}-${month}-${year}`;
        setselectedDate(formattedDate)

        dispatch(fetchAvailabilityData({
            id: data?.id,
            date: formattedDate
        }));

    }
    const handleSelectSlot = (id, avail) => {

        if (avail == 1) {
            setselectSlot(id)
        }
    }
    const handleDateChange = (selectedDate) => {
        setDate1(selectedDate);
        const formattedcusDate = selectedDate?.toLocaleDateString('en-US', options);

        setcustomdate(formattedcusDate)
        getAvailableSlot({
            id: providerId,
            date: selectedDate
        })
        setShowCalendar(false); // Close the calendar when a date is selected
    };
    const handlebooking = async () => {
        if (selectSlot) {
            dispatch(setselectedSlot({ id: selectSlot, date: selectedDate1 }))
            // navigate('/booking-detail/' + id?.id)
            dispatch(fetchAvailableSlotByid({ id: selectSlot, date: selectedDate }))
            const data = {
                booking_id: bookingId,
                time_slot_id: selectSlot,
                date: selectedDate
            }
            try {
                const response = await rescheduleBooking(data);
                if (response.Status == 1) {
                    toast.success(response.Message)
                    getbookings({ page })
                    const modalElement = document.getElementById(`exampleModal${bookingId}`);
                    const isModalOpen = modalElement && modalElement.classList.contains('show');
                    // If modal is open, and button exists, trigger click on the button
                    const button = document.getElementById("cancel");
                    if (button && isModalOpen) {
                        button.click();
                    }
                    setselectSlot()
                } else {
                    toast.error(response.Message)
                }

            } catch (error) {

            }
        } else {
            toast.warn("Please select a slot")
        }
    }
    return (
        <div className="row">
            <Loaders loading={loading} />
            <AccountSideBar page="bookings" />
            <div className="col-12 col-lg-9">
                <div className="my-account" style={{ background: "transparent", boxShadow: "unset", paddingTop: "0" }}>
                    <h2>My Bookings </h2>
                    {bookinglist?.length > 0 ?
                        <>
                            {bookinglist?.map((obj) => {
                                const date = new Date(`2000-01-01T${obj.booking_time_from}`);
                                const formattedStart = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

                                const date2 = new Date(`2000-01-01T${obj.booking_time_to}`);
                                const formattedEnd = date2.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

                                const options = { day: '2-digit', month: 'long', year: 'numeric' };
                                const bookdate = new Date(obj.booking_date_ist)
                                const formattedDate = bookdate.toLocaleDateString('en-US', options);
                                return (
                                    <div className="bookigs-details-single" key={obj?.id}>
                                        <div className="row">
                                            <div className="col-12 col-md-3">
                                                <div className="individual-img">
                                                    <img src={obj.service_provider?.service_provider_photo_url}></img>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <button className={obj.status.id == 2 ? 'approved' : "notapproved"}>{obj.status?.book_status}</button>
                                                <h3>{obj?.service_provider?.service_provider_name}</h3>
                                                {obj?.service_provider?.provider_type == 1?
                                                <span>{obj?.service_provider?.category?.category_name}, {obj.service_provider?.specialty?.speciality_name}</span>
                                                :<span>{obj.service_provider?.specialty?.speciality_name}, {obj?.firm_name}</span>}
                                                {/* <span style={{ fontStyle: "italic" }}></span> */}
                                                <p class="time_bk">{formattedStart}-{formattedEnd}</p>
                                                <p class="time_bk" style={{ fontWeight: "400" }}>{formattedDate}</p>
                                            </div>
                                            <div className="col-12 col-md-3">
                                                <button className='details' onClick={() => navigate('/booking-view/' + obj.id)}>View Details</button>
                                                <button className='reschedule' onClick={() => handlereschedule(obj?.id, obj?.service_provider?.id, obj.booking_date_ist)} data-toggle="modal" data-target={`#exampleModal${obj?.id}`}>Reschedule</button>

                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </> :
                        <div style={{ textAlign: "center" }}>No Bookings !</div>
                    }
                </div>
                <div style={{ marginTop: "50px" }}>
                    <ResponsivePagination
                        current={page}
                        total={totalPages}
                        onPageChange={handlePageChange}
                    />
                </div>

            </div>
            <div className="modal fade button-main" id={`exampleModal${bookingId}`} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <Tabs>
                            <TabList>
                                <Tab
                                    onClick={() => getAvailableSlot({
                                        id: providerId,
                                        date: formattedDate1
                                    })}
                                >
                                    {currentDate ? (new Date(currentDate)?.toLocaleDateString('en-US', options3) == new Date()?.toLocaleDateString('en-US', options3) ? 'Today' : day1) : day1}<p>{formattedDate1}</p>
                                </Tab>
                                <Tab onClick={() => getAvailableSlot({
                                    id: providerId,
                                    date: formattedDate2
                                })}>
                                    {currentDate ? (new Date(currentDate)?.toLocaleDateString('en-US', options3) == new Date()?.toLocaleDateString('en-US', options3) ? 'Tomorrow' : day2) : day2}<p>{formattedDate2}</p>
                                </Tab>
                                <Tab onClick={() => handleButtonClick({
                                    id: providerId,
                                    date: customdate
                                })}>More date<p>{formattedDate3 ? formattedDate3 : 'See calender'}</p>
                                </Tab>
                            </TabList>
                            {showCalendar && (
                                <Calendar onChange={handleDateChange} value={date1} minDate={today} />
                            )}
                            {availableSlots ?
                                <div className="row large-2 p-3">
                                    <div className="row">
                                        {availableSlots?.map((slot, index) => {
                                            const date = new Date(`2000-01-01T${slot.time_start}`);
                                            const formattedStart = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

                                            const date2 = new Date(`2000-01-01T${slot.time_end}`);
                                            const formattedEnd = date2.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

                                            return (
                                                <div className="col-lg-4 col-md-6 col-sm-12" key={index} >
                                                    <div className={slot.is_expired == 1 ? "availblae-active-checktime" :
                                                        slot.is_available == 0 ? "availblae-active-disactive" :
                                                            slot.id === selectSlot ? "availblae" : "availblae-active"}
                                                        onClick={() => handleSelectSlot(slot.id, slot.is_available)} >
                                                        {formattedStart} - {formattedEnd}<br />
                                                        {slot.remaining_count} Available
                                                    </div>
                                                </div>

                                            )
                                        })}
                                    </div>
                                </div> :
                                <div className="row large-2" >
                                    <div className='col-12' style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <p>{sloteMsg}</p>
                                    </div>
                                </div>
                            }

                            <div className="buttons_boo nobordr">
                                <button className="cancel_bkn" data-dismiss="modal" id="cancel">Cancel</button>
                                <button className={selectSlot?"schedule_bkng":"schedule_bkng-disable"} onClick={handlebooking} disabled={selectSlot? false: true}> Book Now </button>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookingList