import React, { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Typography from '@mui/material/Typography';
import AccountSideBar from "./AccountSideBar";
import './asset/Myfamily.css'
import dayjs from 'dayjs';
import { useParams, useNavigate } from "react-router-dom";
import moment from 'moment';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getBloodgrouplist, relationList, updateFamilyMember } from "../../services/ApiServices";
import { fetchFamilylist } from "../../store/FamilymemberList";
import { setEditMember } from "../../store/userSlice";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const MyfamilyformEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { editMember } = useSelector((state) => state.userDetails);
  const formattedDate = dayjs(editMember?.dob, 'DD-MM-YYYY').format('YYYY-MM-DD');
  const [date2, setDate2] = useState(dayjs(formattedDate));
  const [bloodGrp, setBloodGrp] = useState([]);
  const [relation, setRelation] = useState([]);

  const mailregex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().required("Email is required").matches(mailregex, "Invalid email"),
    mobile: yup.string().required("Mobile number required"),
    gender: yup.string().required("Gender is required"),
    relation: yup.string().required("Relation is required"),
    dob: yup.string().required("Date of birth is required"),
    blood_group: yup.string().required("Blood group is required"),
    address: yup.string().required("Address is required"),
  })
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    const dateObj = new Date(date2?.$d);

    const formattedDate = `${String(dateObj.getUTCDate() + 1).padStart(2, '0')}-${String(dateObj.getUTCMonth() + 1).padStart(2, '0')}-${dateObj.getUTCFullYear()}`;
    if (formattedDate === "NaN-NaN-NaN") {
      setError('dob', { type: 'custom', message: "Enter valid date" });
    }
    else {
      const inputdata = {
        id: editMember?.id,
        name: data.name,
        mobile: data.mobile,
        email: data.email,
        gender: data.gender,
        blood_group: data.blood_group,
        dob: data.dob,
        address: data.address,
        relation: data.relation
      }
      try {
        const response = await updateFamilyMember(inputdata);
        toast.success(response.Message)
        dispatch(fetchFamilylist())
        dispatch(setEditMember())
        navigate('/my-family')
      } catch (error) {

        // Add logic for handling login failure (e.g., show error message)

      }
    }


  }
  useEffect(() => {
    listRelation()
    listBloodgroup()
    setValue('dob', dayjs(formattedDate))
    if (editMember && editMember.dob) {
      const formattedDate = dayjs(editMember?.dob, 'DD-MM-YYYY').format('YYYY-MM-DD');
      setDate2(dayjs(formattedDate));
    }
  }, [])
  const listRelation = async () => {
    try {
      const response = await relationList();
      setRelation(response.Data)
    } catch (error) {

    }
  }
  const listBloodgroup = async () => {
    try {
      const response = await getBloodgrouplist();
      setBloodGrp(response.Data)
    } catch (error) {

    }
  }
  const handleDateChange2 = (selectedDate) => {
    setDate2(selectedDate);
    setValue("dob", selectedDate)
    let dateconvert = moment(selectedDate).format('YYYY-MM-DD')
 
  };

  const RequiredLabel = ({ labelText }) => (
    <div>
      <Typography component="span">{labelText}</Typography>
      <Typography component="span" style={{ color: 'red', fontSize: "20px" }}>
        *
      </Typography>
    </div>
  );
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <AccountSideBar page={"myfamily"} />
        <div className="col-12 col-lg-9">
          <div className="my-account">
            <h2>Edit Family Member </h2>
            <div className="row textfield-row-main">
              <div className="col-6">
                <TextField
                  id="standard-basic"
                  label={<RequiredLabel labelText="Name" />}
                  variant="standard"
                  defaultValue={editMember?.name} name='name' {...register("name")}
                />
                <div className="errorDetails">{errors.name ? errors.name.message : ''}</div>
              </div>
              <div className="col-6">
                <TextField
                  id="standard-basic"
                  label={<RequiredLabel labelText="Email" />}
                  variant="standard"
                  defaultValue={editMember?.email} name='email' {...register("email")}
                />
                <div className="errorDetails">{errors.email ? errors.email.message : ''}</div>
              </div>
            </div>

            <div className="row textfield-row-main">
              <div className="col-6">
                <TextField
                  id="standard-basic"
                  label={<RequiredLabel labelText="Mobile Number" />}
                  defaultValue={editMember?.mobile} variant="standard" name='mobile' {...register("mobile")}
                  inputProps={{ maxLength: 10 }}
                />
                <div className="errorDetails">{errors.mobile ? errors.mobile.message : ''}</div>
              </div>
              <div className="col-6 select">
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-standard-label">{<RequiredLabel labelText="Relation" />}</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="relation"
                    {...register("relation")}
                    defaultValue={editMember?.relation_id}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {relation?.map((obj, index) => (<MenuItem key={obj?.id} value={obj?.id}>{obj.name}</MenuItem>))}
                  </Select>
                </FormControl>
                <div className="errorDetails">{errors.relation ? errors.relation.message : ''}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120, margin: "0", marginBottom: "15px" }}>
                  <InputLabel id="demo-simple-select-standard-label">{<RequiredLabel labelText="Gender" />}</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="gender"

                    {...register("gender")}
                    defaultValue={editMember?.gender_id}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value='1'>Male</MenuItem>
                    <MenuItem value='2'>Female</MenuItem>
                    <MenuItem value='3'>Other</MenuItem>
                  </Select>
                </FormControl>
                <div className="errorDetails">{errors.gender ? errors.gender.message : ''}</div>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120, margin: "0", marginBottom: "15px" }}>
                  <InputLabel id="demo-simple-select-standard-label">{<RequiredLabel labelText="Bloodgroup" />}</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard" s
                    label="Blood group"
                    name="blood_group"
                    {...register("blood_group")}
                    defaultValue={editMember?.blood_group_id}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {bloodGrp?.map((obj, index) => (<MenuItem key={obj?.id} value={obj?.id}>{obj.blood_group_name}</MenuItem>))}
                  </Select>
                </FormControl>
                <div className="errorDetails">{errors.blood_group ? errors.blood_group.message : ''}</div>
                <div className="textfield-row-main">
                  <div className="datepicker">
                    <LocalizationProvider dateAdapter={AdapterDayjs} style={{ marginBottom: "15px" }}>
                      <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                        <DatePicker
                          {...register('dob')}
                          label={<RequiredLabel labelText="Dob" />}
                          disableFuture
                          value={date2}
                          onChange={handleDateChange2}
                        />
                        <div className="errorDetails">{errors.dob ? errors.dob.message : ''}</div>
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </div>


              </div>
              <div className="col-6">
                <div className="textarea">
                  <label for="w3review"><RequiredLabel labelText="Address" /></label>

                  <textarea id="w3review" name='address' defaultValue={editMember?.address} {...register("address")}>

                  </textarea>
                  <div className="errorDetails">{errors.address ? errors.address.message : ''}</div>
                </div>

              </div>
            </div>
            <button className="save" type="submit">Update</button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default MyfamilyformEdit