import React from 'react';
import check from "../../components/service/assets/Images/Check circle.svg";
import Vector from "../../components/service/assets/Images/Vector.svg";
import { useNavigate } from 'react-router-dom';

function ListingFirm({ data }) {
    const navigate = useNavigate()
    const distance = data?.distance/1000 
    return (
        <>
            <div className="col-12 col-lg-6 mt-3">
                <div className="doctor-details">
                    <div className="row">
                        <div className="col-4">
                            <img src={data?.service_provider_photo_url} className="img-first" />
                        </div>
                        <div className="col-8">
                            <div className="doctor-name">
                                <h3>{data?.service_provider_name}</h3>
                                <span><img src={check} /></span>
                            </div>
                            <h5>{data?.category?.category_name}</h5>
                            <h5>{data?.specialty?.speciality_name}</h5>
                            
                        </div>
                    </div>
                    <div className="row" style={{ margin: '10px 0' }}>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-1" style={{ paddingLeft: 0 }}>
                                            <img src={Vector} className="location" />
                                        </div>
                                        <div className="col-6 Booking-num">{data?.location?.location_name + ',' + data?.city?.city_name}</div>
                                        <div className="col-3 Booking-num">{distance?.toFixed(2)} Km</div>
                                    </div>
                                </div>
                            </div>

                    <div className="row" style={{ marginTop: '15px' }}>
                        <div className="col-6">
                            <button className="profile-view" onClick={() => navigate('/view-firm-profile/' + data?.id)}>View Profile</button>
                        </div>
                        <div className="col-6"><button className="book-now btn btn-primary"
                            data-toggle="modal" data-target="#exampleModal" onClick={()=>navigate('/view-firm-members/'+data?.id,{state:{catId:data?.category_id}})}>View Members</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* popup */}
        </>
    )
}

export default ListingFirm
