import React,{useEffect} from 'react';
import { useDispatch,useSelector } from 'react-redux';
import AppHeader from '../components/Common/Header/AppHeader';
import AppFooter from '../components/Common/AppFooter';
import '../components/BookingDetails/assets/bookingDetail.css';
import DetailCard from '../components/BookingDetails/DetailCard';
import Membercard from '../components/BookingDetails/Membercard';
import { fetchserviceProvDetail } from '../store/ServiceproviderDetail';
import { useParams } from "react-router-dom";
import '../components/BookingDetails/assets/bookingDetail.css';

function BookingDetail() {
    const id = useParams();
    const dispatch = useDispatch()
    const { userInfo } = useSelector((state) => state.userDetails);
    useEffect(()=>{
        dispatch(fetchserviceProvDetail({ id, userInfo }))
    },[])
    return (
        <div className="listing-service-main">
            <AppHeader />
            <div className="mainP HomePage">
                <div className="list_page_layout">
                    <div className="listing-main">
                        <div className="wrapper">
                            <div className="row mt-5 mb-3">
                                <div className="col-12 col-lg-8">
                                    <DetailCard />
                                </div>
                                <div className="col-12 col-lg-4">
                                    <Membercard />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AppFooter />
        </div>
    )
}

export default BookingDetail
