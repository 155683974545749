import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from '../src/views/Home'
import ErrorNull from "./views/ErrorNull";
import AuthLayout from "./views/AuthLayout";
import { RouterData } from './routing/AuthRouting';
import { RouterData2 } from './routing/AccountRouting';
import AboutUs from "./views/Aboutus";
import NewsnEvents from "./views/NewsnEvents";
import NewsDetailpage from "./views/NewsDetailpage";
import ContactUs from "./views/Contactus";
import CareerList from "./views/Career";
import PrivacyPolicy from "./views/PrivacyPolicy";
import TermsandConditions from "./views/TermsandConditions";
import ServiceProvider from "./views/ServiceProvider";
import ViewProfile from "./views/ViewProfile";
import BookingDetail from "./views/BookingDetail";
import ViewFirmDetail from "./views/ViewFirmDetail";
import MyFamilyEdit from "./views/MyFamilyEdit";
import BookingView from "./views/BookingView";
import MyfamilyOtp from "./views/MyfamilyOtp";
import UpdateKyc from "./views/UpdateKyc";
import EditFamilymember from "./views/EditFamilymember";
import BookingSummury from "./views/BookingSummury";
import Viewmembers from "./components/Detail/Viewmembers";
import BookingConfirm from "./views/BookingConfirm";
import AllSpeciality from "./views/AllSpeciality";
function App() {
 
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        {RouterData?.map((idx) => (
          <Route path="/" element={<AuthLayout />} key={Math.random()}>
            <Route path={idx.path} element={idx.element} />
          </Route>
        ))};
         {RouterData2?.map((idx) => (
          <Route path="/" element={<AuthLayout />} key={Math.random()}>
            <Route path={idx.path} element={idx.element} />
          </Route>
        ))};
        <Route path="services" element={<ServiceProvider />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="news-events" element={<NewsnEvents />} />
        <Route path="news-details" element={<NewsDetailpage />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="careers" element={<CareerList />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms-conditions" element={<TermsandConditions />} />
        <Route path="view-profile/:id" element={<ViewProfile />} />
        <Route path="view-firm-profile/:id" element={<ViewFirmDetail />} />       
        <Route path="booking-detail/:id" element={<BookingDetail />} />   
        <Route path="booking-view/:id" element={<BookingView />} />
        <Route path="family-otp-verify" element={<MyfamilyOtp/>} />
        
        <Route path="edit-family-membar" element={<EditFamilymember/>}/>
        <Route path="booking-summury/:id" element={<BookingSummury/>}/>
        <Route path="view-firm-members/:id" element={<Viewmembers/>}/>
        <Route path="booking-confirmed" element={<BookingConfirm/>}/>
        <Route path="specialities" element={<AllSpeciality/>}/>

        <Route path="*" element={<ErrorNull />} />
      </Routes>
    </div>
  );
}

export default App;