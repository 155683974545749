import React, { useEffect, useState } from 'react'
import AppHeader from '../components/Common/Header/AppHeader'
import AppFooter from '../components/Common/AppFooter'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setSelectedSpeciality } from '../store/FilterSliceList'

const AllSpeciality = () => {
  const location = useLocation()
  const [data, setdata] = useState(location?.state?.specialities)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const search = (id, name) => {
    dispatch(setSelectedSpeciality({ label: name, value: id }))
    if (id) {
      navigate('/services')
    }};
  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);
  return (
    <div className="inner_page_layout common_page">
      <AppHeader />
      <div className="layout-pg">
        <div className="content_pg">
          <div className="wrapper">

            <h2 style={{ padding: "40px 0px 40px 20px" }} className='tile-h sp-h'>Specialities</h2>

            <div className="row">
              {data?.map((item) => {
                return (
                  <div className="col-12 col-lg-2 col-md-4 col-sm-6">
                    <div className="single_box item">
                      <img src={item.speciality_icon_url} alt={item.speciality_name} />
                     
                      <h3 >{item.speciality_name}</h3>
                      <p onClick={() => search(item.id, item.speciality_name)} className='spec-book'>Book Now</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>



        </div>
      </div>
      <AppFooter />
    </div>
  )
}

export default AllSpeciality