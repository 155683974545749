import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { familymembarList } from "../services/ApiServices"


export const fetchFamilylist = createAsyncThunk(
  "filters/fetchFamilylist",
  async (page) => {
    const data = await familymembarList(page);
    return data
  }
);
const Familylistslice = createSlice({
  name: "AccountDetail",
  initialState: {
    myFamily: []
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFamilylist.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFamilylist.fulfilled, (state, action) => {
        state.myFamily = action.payload.Data.data;
        state.pagination = action.payload.Data;
        state.loading = false;
      })
      .addCase(fetchFamilylist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
} = Familylistslice.actions;

export default Familylistslice.reducer;