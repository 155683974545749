import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { viewserviceprovider } from "../services/ApiServices"


export const fetchserviceProvDetail = createAsyncThunk(
  "filters/fetchserviceProvDetail",
  async ({id,userInfo}) => {
   
    const data = await viewserviceprovider({id,userInfo});
        return data
      }
    );
const ProviderdetailsSlice = createSlice({
  name: "ProviderSearch",
  initialState: {
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchserviceProvDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchserviceProvDetail.fulfilled, (state, action) => {
        state.viewserviceprovider=action.payload.Data;
        state.sloteMsg=action.payload.Message;
        state.loading = false;
      })
      .addCase(fetchserviceProvDetail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { 
 } = ProviderdetailsSlice.actions;

export default ProviderdetailsSlice.reducer;